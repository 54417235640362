<template>

  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="item in groups.path" :key="item.deptId">
        <el-link type="primary" @click="click_fun_goto(item.deptId)">{{ item.name }}</el-link>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div style="background-color: rgb(218, 205, 205); width: 100%; height: auto;">
      <el-button v-for="item in group_list" :key="item.deptId" type="text" @click="click_fun_goto(item.dept_id)">{{ item.name }}</el-button>
    </div>
    <el-tabs :v-if="user_list">
      <el-table :data="user_list" style="width: auto">
        <el-table-column prop="userid" label="id" width="200px" />
        <el-table-column prop="name" label="用户名" width="100px" />
        <el-table-column prop="mobile" label="电话" width="200px" />
        <el-table-column width="auto">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-date" :circle="true" @click="click_fun_to_edit(scope.row.dept_id_list[0], scope.row.userid, scope.row.name)" />
            <el-button type="primary" icon="el-icon-time" :circle="true" @click="click_fun_auth(scope.row.userid, scope.row.name)" />
          </template>
        </el-table-column>
      </el-table>
    </el-tabs>
  </div>

</template>

<script>
export default {
  data () {
    return {
      user_list: [],
      group_list: [],
      groups: { path: [{ deptId: 1, name: '根目录' }], IdName: [{ dept_id: 1, name: '根目录' }] }
    }
  },
  created: function () {
    const path = JSON.parse(localStorage.getItem('department_list_path'))
    if (Object.prototype.toString.call(path) === '[object Object]') {
      this.groups = path
      this.get_user_list(path.path[path.path.length - 1].deptId)
      this.get_group_list(path.path[path.path.length - 1].deptId)
    } else {
      this.get_user_list(1)
      this.get_group_list(1)
    }
  },
  methods: {
    // 获取
    get_group_list (deptId) {
      this.$axios({
        method: 'post',
        data: { dept_id: deptId },
        url: '/dingtalk/index/get_department_list'
      }).then(res => {
        if (Number(res.data.status) === 0) {
          var g1 = this.groups
          this.group_list = res.data.data.result

          // 取dept_id与name对照表
          for (let a1 = 0; a1 < this.group_list.length; a1++) {
            g1.IdName.push(this.group_list[a1])
          }
          // 去重
          for (let a2 = 0; a2 < g1.IdName.length; a2++) {
            for (let b2 = (a2 + 1); b2 < g1.IdName.length; b2++) {
              if (g1.IdName[a2].dept_id === g1.IdName[b2].dept_id) {
                g1.IdName.splice(b2, 1)
              }
            }
          }
          // 取得路径
          g1.path.push({ deptId: deptId, name: '' })
          for (let a3 = 0; a3 < g1.path.length; a3++) {
            for (let b3 = 0; b3 < g1.IdName.length; b3++) {
              if (g1.path[a3].deptId === g1.IdName[b3].dept_id) {
                g1.path[a3].name = g1.IdName[b3].name
                if (g1.path[a3].deptId === deptId) {
                  a3++
                  g1.path.splice(a3, g1.path.length - a3)
                  break
                }
              }
            }
          }
          this.groups = g1
          localStorage.setItem('department_list_path', JSON.stringify(g1))
        } else { this.$message.error(res.data.msg) }
      })
    },
    get_user_list (deptId) {
      this.$axios({
        method: 'post',
        data: { dept_id: deptId },
        url: '/dingtalk/index/get_user_list'
      }).then(res => {
        if (Number(res.data.status) === 0) {
          this.user_list = res.data.data.result.list
          window.sessionStorage.setItem('AttenceUserList_get_user_list', JSON.stringify(this.user_list))
        }
      })
      try { this.user_list = JSON.parse(window.sessionStorage.getItem('AttenceUserList_get_user_list')) } catch {} // 先从缓存取再去服务器取
    },
    click_fun_goto (deptId) {
      this.get_user_list(deptId)
      this.get_group_list(deptId)
    },
    click_fun_auth (userid, name) {
      this.$router.push({ path: '/attence/user_edit', query: { userid: userid, name: name } })
    },
    click_fun_to_edit (deptId, userid, name) {
      this.$router.push({ path: '/attence/get', query: { deptId: deptId, userid: userid, name: name } })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
