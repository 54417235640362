<template>
  <h1>Welcome</h1>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
</style>
