import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Home from '../views/home/Home.vue'
import HomeMain from '../views/home/Main.vue'
import HomeWelcome from '../views/home/Welcome.vue'
import UserList from '../views/user/List.vue'
import UserEdit from '../views/user/Edit.vue'
import UserAuth from '../views/user/Auth.vue'
import UserSelfInfo from '../views/user/SelfInfo.vue'

import AttenceGet from '../views/attence/AttenceGet.vue'
import AttenceUserList from '../views/attence/AttenceUserList.vue'
import AttenceUserEdit from '../views/attence/AttenceUserEdit.vue'

import MaterialList from '../views/material/List.vue'
import MaterialEdit from '../views/material/Edit.vue'
import MaterialFile from '../views/material/File.vue'
import MaterialInOut from '../views/material/Inout.vue'
import MaterialAddType from '../views/material/Type.vue'
import MaterialAddMaterial from '../views/material/Add.vue'

import BomList from '../views/bom/List.vue'

import PurchaseList from '../views/purchase/List.vue'
import PurchaseEdit from '../views/purchase/Edit.vue'
import PurchaseAdd from '../views/purchase/Add.vue'
import PurchaseDetails from '../views/purchase/Details.vue'

import FinanceList from '../views/finance/List.vue'
import FinanceEdit from '../views/finance/Edit.vue'
import FinanceAccount from '../views/finance/Account.vue'

import ProduceAdd from '../views/produce/Add.vue'
import ProduceList from '../views/produce/List.vue'
import ProduceWorks from '../views/produce/Works.vue'
import ProduceEdit from '../views/produce/Edit.vue'

import NotesList from '../views/notes/List.vue'
// import NotesOrganize from '../views/notes/Organize.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    redirect: '/home/welcome',
    children: [
      {
        path: '/home/welcome',
        component: HomeWelcome
      },
      {
        path: '/notes',
        component: HomeMain,
        redirect: '/notes/list',
        children: [
          { path: '/notes/list', component: NotesList }
          // { path: '/notes/organize', component: NotesOrganize }
        ]
      },
      {
        path: '/finance',
        component: HomeMain,
        redirect: '/finance/list',
        children: [
          { path: '/finance/list', component: FinanceList },
          { path: '/finance/edit', component: FinanceEdit },
          { path: '/finance/account', component: FinanceAccount }
        ]
      },
      {
        path: '/users',
        component: HomeMain,
        redirect: '/users/list',
        children: [
          { path: '/users/list', component: UserList },
          { path: '/users/auth', component: UserAuth },
          { path: '/users/edit', component: UserEdit },
          { path: '/users/selfinfo', component: UserSelfInfo }
        ]
      },
      {
        path: '/attence',
        component: HomeMain,
        redirect: '/attence/user_list',
        children: [
          { path: '/attence/get', component: AttenceGet },
          { path: '/attence/user_list', component: AttenceUserList },
          { path: '/attence/user_edit', component: AttenceUserEdit }
        ]
      },
      {
        path: '/material',
        component: HomeMain,
        redirect: '/material/list',
        children: [
          { path: '/material/list', component: MaterialList },
          { path: '/material/edit', component: MaterialEdit },
          { path: '/material/file', component: MaterialFile },
          { path: '/material/inout', component: MaterialInOut },
          { path: '/material/type', component: MaterialAddType },
          { path: '/material/add', component: MaterialAddMaterial }
        ]
      },
      {
        path: '/bom',
        component: HomeMain,
        redirect: '/bom/list',
        children: [
          { path: '/bom/list', component: BomList }
        ]
      },
      {
        path: '/produce',
        component: HomeMain,
        redirect: '/produce/list',
        children: [
          { path: '/produce/add', component: ProduceAdd },
          { path: '/produce/list', component: ProduceList },
          { path: '/produce/works', component: ProduceWorks },
          { path: '/produce/Edit', component: ProduceEdit }
        ]
      },
      {
        path: '/purchase',
        component: HomeMain,
        redirect: '/purchase/list',
        children: [
          { path: '/purchase/add', component: PurchaseAdd },
          { path: '/purchase/list', component: PurchaseList },
          { path: '/purchase/edit', component: PurchaseEdit },
          { path: '/purchase/details', component: PurchaseDetails }
        ]
      }
    ]
  },
  {
    path: '/login', component: Login
  }
]

const router = new VueRouter({
  routes
})

// 路由导航守卫控制访问权限
router.beforeEach((to, from, next) => {
  if (to.path === '/login') { return next() }
  window.sessionStorage.setItem('UrlRestore', to.fullPath)
  if (!window.sessionStorage.getItem('token')) { return next('/login') }
  next()
})

export default router
