<template>

  <div>
    <el-page-header content="添加物料类型" @back="$router.go(-1);" />
    <br>

    <el-card class="box-card">
      <el-row>
        <el-col :span="15"><el-input v-model="describe[0]" placeholder="一级类型" /></el-col>
        <el-col :span="3"><el-button style="margin-left: 20px;" @click="fun_type_add(0)">添  加</el-button></el-col>
      </el-row>

      <el-row style="padding-top: 10px;">
        <el-col :span="15"><el-input v-model="describe[1]" placeholder="二级类型" /></el-col>
        <el-col :span="3"><el-button style="margin-left: 20px;" @click="fun_type_add(1)">添  加</el-button></el-col>
      </el-row>
      <br>
      <table v-if="list.data.length > 0" class="el-table" style="width:auto">
        <tr>
          <th style="text-align:center;">编号</th>
          <th style="text-align:center;">描述</th>
          <th style="text-align:center;" />
        </tr>
        <template v-for="(data, row) in list.data" :v-bind:key="row">
          <tr :key="row">
            <td v-if="data.type == 0" style="padding:0px 10px 0px 10px">{{ data.sn }}.xxx</td>
            <td v-else style="padding:0px 10px 0px 10px">xxx.{{ data.sn }}</td>
            <td style="padding:0px 10px 0px 10px">{{ data.describe }}</td>
            <td style="padding:0px 10px 0px 10px"><el-button size="mini" @click="fun_type_del(row)">删除</el-button></td>
          </tr>
        </template>
      </table>

      <el-row>
        <el-col :span="15"><el-input v-model="screen" placeholder="查找" /></el-col>
        <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_type(screen)">查找</el-button></el-col>
      </el-row>

      <div :v-if="list">
        <el-pagination
          hide-on-single-page
          :page-size="list.per_page"
          :page-count="list.last_page"
          :current-page="list.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChange_type"
          @current-change="handleCurrentChange_type"
        />
      </div>
    </el-card>

    <br>

    <el-card class="box-card">
      <table v-if="log.data.length > 0" class="el-table" style="width:100%">
        <tr>
          <th style="text-align:center; width:170px">时间</th>
          <th style="text-align:center; width:auto">用户</th>
          <th style="text-align:center;" />
        </tr>
        <template v-for="(data, row) in log.data" :v-bind:key="row">
          <tr :key="row">
            <td style="text-align:center; padding:2px 10px 2px 10px">{{ data.time }}</td>
            <td style="text-align:center; padding:2px 10px 2px 10px">{{ data.uid }} / {{ data.name }}</td>
            <td style="text-align:left;   padding:2px 10px 2px 10px">{{ data.log }}</td>
          </tr>
        </template>
      </table>
      <div :v-if="log.data">
        <el-pagination
          hide-on-single-page
          :page-size="log.per_page"
          :page-count="log.last_page"
          :current-page="log.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChange_log"
          @current-change="handleCurrentChange_log"
        />
      </div>
    </el-card>

  </div>

</template>

<script>
export default {
  data () {
    return {
      list: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      screen: '',
      describe: ['', '']
    }
  },
  created: function () {
    this.get_log()
    this.get_type('')
  },
  methods: {
    get_type (ss) {
      this.$axios({
        method: 'post',
        url: '/material/index/get_type',
        data: {
          screen: ss,
          order: 'desc',
          per_page: this.list.per_page,
          current_page: this.list.current_page
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    get_log () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_type_log',
        data: {
          per_page: this.log.per_page,
          current_page: this.log.current_page
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    fun_type_add (type) {
      this.$axios({
        method: 'post',
        url: '/material/index/set_type',
        data: { type: type, describe: this.describe[type] }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.get_log()
          this.get_type()
          this.$message.success(res.data.msg)
        }
      })
    },
    fun_type_del (row) {
      this.$confirm('确认删除?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        this.$axios({
          method: 'post',
          url: '/material/index/set_type',
          data: { type: 'del', id: this.list.data[row].id }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.get_log()
            this.get_type()
            this.$message.success(res.data.msg)
          }
        })
      })
    },
    handleSizeChange_log (val) { this.log.per_page = val; this.get_log() },
    handleSizeChange_type (val) { this.list.per_page = val; this.get_type() },
    handleCurrentChange_log (val) { this.log.current_page = val; this.get_log() },
    handleCurrentChange_type (val) { this.list.current_page = val; this.get_type() }
  }
}
</script>

<style lang="less" scoped>

</style>
