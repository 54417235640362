<template>

  <div>
    <table v-if="account.length > 0" class="el-table" style="width:auto">
      <tr>
        <th style="width:30px; text-align: center;">序号</th>
        <th style="width:150px; text-align: center">时间</th>
        <th style="width:auto; text-align: center">帐户</th>
      </tr>
      <template v-for="(data, row) in account" :v-bind:key="row">
        <tr :key="row">
          <td style="text-align: center">{{ row + 1 }}</td>
          <td style="text-align: center">{{ data.time }}</td>
          <td style="text-align: center">{{ data.account }}</td>
          <td style="padding:0px 5px 0px 20px">
            <el-button type="primary" icon="el-icon-edit" circle @click="edit_id=row, dialog_edit=true, get_account_log(data.number)"></el-button>
          </td>
        </tr>
      </template>
    </table>
    <button @click="dialog_add=true">添加新帐户</button>

    <el-dialog v-if="dialog_add"
        title="添加新帐户"
        :visible.sync="dialog_add"
        width="30%">
        <el-input placeholder="请输入帐户名" v-model="new_account">
          <template slot="prepend">帐户名</template>
        </el-input>
        <br>
        <button style="width:100%" @click="fun_add_account(new_account)">添加</button>
    </el-dialog>

    <el-dialog v-if="dialog_edit"
        title="编辑帐户"
        :visible.sync="dialog_edit"
        width="90%">
        <el-input placeholder="请输入帐户名" v-model="account[edit_id].account" class="input-with-select" style="width:500px">
            <template slot="prepend">帐户名</template>
            <el-button slot="suffix" icon="el-icon-edit" @click="fun_edit_account(account[edit_id].id, 'account', account[edit_id].account)"></el-button>
            <el-button slot="append" icon="el-icon-delete" @click="fun_edit_account(account[edit_id].id, 'del',     account[edit_id].account)"></el-button>
        </el-input>

        <br>
        <table v-if="log.data.length > 0" class="el-table" style="width:auto">
            <tr>
                <th style="width:100px; text-align: center;">序号</th>
                <th style="width:150px; text-align: center;">时间</th>
                <th style="width:100px; text-align: center;">帐户</th>
                <th style="width:auto; text-align: center;">内容</th>
            </tr>
            <template v-for="(data, row) in log.data" :v-bind:key="row">
                <tr :key="row">
                    <td style="text-align: center">{{ row+1 }}</td>
                    <td style="text-align: center">{{ data.time }}</td>
                    <td style="text-align: center">{{ data.user }}({{ data.uid }})</td>
                    <td style="text-align: left">{{ data.log }}</td>
                </tr>
            </template>
        </table>
        <div :v-if="log.data">
            <el-pagination
                hide-on-single-page
                :page-size="log.page_size"
                :page-count="log.last_page"
                :current-page="log.current_page"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      account: [],
      edit_id: -1,
      new_account: '',
      dialog_add: false,
      dialog_edit: false
    }
  },
  created: function () {
    this.get_account()
  },
  methods: {
    get_account (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/finance/index/account_get',
        data: { page_size: this.log.page_size, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.account = res.data.data
        }
      })
    },
    get_account_log (number = null, current_page = null) {
      this.$axios({
        method: 'post',
        url: '/finance/index/account_log_get',
        data: { per_page: this.log.per_page, current_page: current_page, number: number }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
      this.log.data = []
    },
    fun_add_account (account) {
      this.$axios({
        method: 'post',
        data: { account: account },
        url: '/finance/index/account_add'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        }
        this.get_account()
        this.dialog_add = false
      })
    },
    fun_edit_account (id, key, value) {
      this.$axios({
        method: 'post',
        url: '/finance/index/account_edit',
        data: { id: id, key: key, value: value }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.success(res.data.msg)
        }
        this.get_account()
        this.dialog_edit = false
      })
    },
    handleSizeChange (val) {
      this.get_account_log(this.account[this.edit_id].number, val)
    },
    handleCurrentChange (val) {
      this.get_account_log(this.account[this.edit_id].number, val)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
