<template>

  <div>
    <!-- {{details.data}}---{{account}} -->
    <div v-if="list.data.length == 1">
      <el-row>
        <el-col :span="1"> <label>单号: </label> </el-col>
        <el-col :span="5"> <label>{{number}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>料号: </label> </el-col>
        <el-col :span="10"> <label>{{list.data[0].sn}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>用量: </label> </el-col>
        <el-col :span="10"> <label>{{list.data[0].amounts}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>单位: </label> </el-col>
        <el-col :span="10"> <label>{{list.data[0].unit}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>位置: </label> </el-col>
        <el-col :span="10"> <label>{{list.data[0].position}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>型号: </label> </el-col>
        <el-col :span="10"> <label>{{list.data[0].model}} </label> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>总数: </label> </el-col>
        <el-col :span="10">
          <el-input v-model="list.data[0].sum"></el-input>
        </el-col>
        <el-col :span="2"> <el-button @click="fun_edit(list.data[0].id, 'sum', list.data[0].sum)">修改</el-button> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>损耗: </label> </el-col>
        <el-col :span="10">
          <el-input v-model="list.data[0].loss"></el-input>
        </el-col>
        <el-col :span="2"> <el-button @click="fun_edit(list.data[0].id, 'loss', list.data[0].loss)">修改</el-button> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> <label>描述: </label> </el-col>
        <el-col :span="10">
          <el-input v-model="list.data[0].describe"></el-input>
        </el-col>
        <el-col :span="2"> <el-button @click="fun_edit(list.data[0].id, 'describe', list.data[0].describe)">修改</el-button> </el-col>
      </el-row>
      <el-row>
        <el-col :span="1"> </el-col>
        <el-col :span="10"> <el-button style="width:100%" @click="fun_edit(list.data[0].id, 'del', 'del')">删除</el-button> </el-col>
      </el-row>
    </div>
    <br><br>
    <table v-if="log.data.length > 0" class="el-table">
      <tr>
        <th >序号</th>
        <th >uid</th>
        <th >用户</th>
        <th >料号</th>
        <th >时间</th>
        <th >内容</th>
      </tr>

      <template v-for="(data1, row1) in log.data" :v-bind:key="row1">
        <tr :key="row1">
          <td style="padding:5px 20px 5px 20px">{{ row1+1 }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.uid }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.user }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.sn }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.time }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.log }}</td>
        </tr>
      </template>
    </table>
    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChangeLog"
        @current-change="handleCurrentChangeLog"
      />
    </div>
    <br><br><br>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      list: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      sn: this.$route.query.sn,
      number: this.$route.query.number
    }
  },
  created: function () {
    this.log_get()
    this.list_get()
  },
  methods: {
    list_get (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/produce/index/get_works_list',
        data: { number: this.number, sn: this.sn, per_page: this.list.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    log_get (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/produce/index/get_log',
        data: { number: this.number, sn: this.sn, per_page: this.log.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    fun_edit (id, key, value) {
      this.$axios({
        method: 'post',
        url: '/produce/index/edit',
        data: { id: id, key: key, value: value }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log_get(this.log.current_page)
          this.list_get(this.list.current_page)
        }
      })
    },
    handleSizeChangeLog (val) {
      this.log.per_page = val
      this.log_get(this.log.current_page)
    },
    handleCurrentChangeLog (val) {
      this.log_get(val)
      this.log.current_page = val
    }
  }
}
</script>

<style lang="less" scoped>
</style>
