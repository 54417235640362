<template>
  <div>
      <template v-if="treeData.length > 0">
          <el-tree
              :data="treeData"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                  <div style="display: flex; align-items: center; width: 100%;">
                  <span @click="get_log(data.data)">{{ node.label }}</span>
                  <template v-if="data.id">
                      <el-link style="margin-left: auto;" type="primary" @click="get_log(data.data)">{{ data["data"].title }}</el-link>
                  </template>
                  </div>
              </span>
          </el-tree>

          <el-dialog :visible.sync="log.Dialog_Read_Visible" fullscreen v-if="log.val.data[0]">
              <el-dialog
                width="100%"
                :visible.sync="log.Dialog_Edit_Visible"
                append-to-body>
                <el-dialog :visible.sync="Auth.Dialog_Visible" append-to-body width="250px">
                  <table>
                    <tr>
                      <td style="width: 1px;">
                        <template v-for="(Val1, Key1) in log.val.all_users">
                          <el-tag :key="Key1" @click="EditUser('add', Val1)">{{ Val1.name }}</el-tag>
                        </template>
                      </td>
                      <td style="width: 1px;">
                        <template v-for="(Val2, Key2) in log.notes.users">
                          <el-tag :key="Key2" closable @close="EditUser('del', Key2)">{{ Val2 }}</el-tag>
                        </template>
                      </td>
                    </tr>
                  </table>
                  <el-button type="primary" style="width: 100%;" @click="EditUser('EditUsers', log.val.data[0].number)">修改</el-button>
                </el-dialog>
                <el-button icon="el-icon-delete" size="mini" @click="click_fun_edit(log.notes.number, 'Del', '')"></el-button><br>
                <label @click="Auth.Dialog_Visible=true">权限: </label>
                <template v-for="(Val, Key) in log.notes.users">
                  <label :key="Key">{{ Val }} </label>
                </template>
                <el-input placeholder="请输入路径" v-model="log.notes.path">
                    <template slot="prepend">路径</template>
                    <el-button slot="append" icon="el-icon-edit" @click="click_fun_edit(log.notes.number, 'EditPath', log.notes.path)"></el-button>
                </el-input>
                <el-input placeholder="请输入标题" v-model="log.notes.title">
                    <template slot="prepend">标题</template>
                    <el-button slot="append" icon="el-icon-edit" @click="click_fun_edit(log.notes.number, 'EditTitle', log.notes.title)"></el-button>
                </el-input>
              </el-dialog>
              <div style="display: flex; justify-content: space-between;">
                  <label @click="log.Dialog_Edit_Visible=true">{{ log.notes.path }}</label>
                  <label @click="log.Dialog_Edit_Visible=true">{{ log.notes.title }}</label>
                  <label>{{ log.val.data[0].user }}/{{ log.val.data[0].time }}</label>
              </div>
              <quill-editor
                    class="editor"
                    :ref="'QuillEditor'"
                    :options="editorOptions(log.val.data[0].number, log.val.data[0].log)"
                    v-model="log.val.data[0].log"
              ></quill-editor>
              <el-pagination
                hide-on-single-page
                :page-size="log.val.per_page"
                :page-count="log.val.last_page"
                :current-page="log.val.current_page"
                layout="total, prev, pager, next, jumper"
                @current-change="handleCurrentChange($event, log)"
              />
          </el-dialog>
      </template>
      <el-dialog :visible.sync="log.Dialog_AddTitle_Visible">
          <el-input placeholder="请输入路径" v-model="log.notes.path"><template slot="prepend">路径</template></el-input>
          <el-input placeholder="请输入标题" v-model="log.notes.title"><template slot="prepend">标题</template></el-input>
          <el-button @click="click_fun_edit(log.notes.path, 'AddTitle', log.notes.title)" style="width: 100%;">添加</el-button>
      </el-dialog>
      <el-button @click="log.Dialog_AddTitle_Visible=true; log.notes.path=''; log.notes.title=''" size="mini" style="width: 100%;">添加</el-button>
  </div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

// 自定义模块
const Module = Quill.import('core/module')
class CustomButton extends Module {
  constructor (quill, options) {
    super(quill, options)
    // 创建按钮
    const button = document.createElement('button')
    button.innerHTML = '<i class="el-icon-upload" style="font-size: 18px;"></i>' // 使用 Element UI 图标
    button.classList.add('ql-custom-button')
    button.addEventListener('click', () => {
      const editorHTML = this.quill.root.innerHTML // 获取编辑器的 HTML 内容
      // console.log(options.keyVal, editorHTML)
      options.vueInstance.click_fun_edit(options.keyVal, 'EditLog', editorHTML)
    })
    // 将按钮添加到工具栏
    const toolbar = quill.getModule('toolbar')
    toolbar.container.appendChild(button)
  }
}
// 注册自定义模块
Quill.register('modules/customButton', CustomButton)

export default {
  data () {
    return {
      list: {
        data: {},
        total: 1, // 总计
        per_page: 200, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      log: {
        val: {
          data: {},
          total: 1, // 总计
          per_page: 1, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        Dialog_AddTitle_Visible: false,
        Dialog_Read_Visible: false,
        Dialog_Edit_Visible: false,
        notes: { title: '', path: '' }
      },
      treeData: {},
      Auth: { Dialog_Visible: false }
    }
  },
  components: {
    quillEditor
  },
  created: function () {
    this.get_list()
  },
  methods: {
    editorOptions (keyVal, log) {
      return {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ align: [] }], // 对齐方式
            ['clean'] // 清除文本格式
          ],
          customButton: {
            keyVal,
            log,
            vueInstance: this
          }
        }
      }
    },
    generateTreeData (data) {
      const tree = []
      data.forEach(item => {
        const parts = item.path.split('/').filter(Boolean)
        let currentLevel = tree
        parts.forEach((part, index) => {
          let existingPath = currentLevel.find(node => node.label === part)
          if (!existingPath) {
            existingPath = {
              id: index === parts.length - 1 ? item.id : undefined,
              label: part,
              children: []
            }
            currentLevel.push(existingPath)
          }
          if (index === parts.length - 1 && item.user) {
            existingPath.data = item
          }
          currentLevel = existingPath.children
        })
      })
      return tree
    },
    get_list () {
      const self = this
      this.$axios({
        method: 'post',
        url: '/notes/index/get_list',
        data: { per_page: this.list.per_page, current_page: this.list.current_page }
      }).then(res => {
        self.list = res.data.data
        self.treeData = self.generateTreeData(res.data.data.data)
      })
    },
    get_users () {
      const self = this
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_list',
        data: { per_page: this.list.per_page, current_page: this.list.current_page }
      }).then(res => {
        self.list = res.data.data
        self.treeData = self.generateTreeData(res.data.data.data)
      })
    },
    get_log (Dat) {
      this.$axios({
        method: 'post',
        url: '/notes/index/get_log',
        data: { number: Dat.number, page: this.log.val.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log.title = Dat.title
          this.log.val = res.data.data
        }
      })
      this.log.notes = Dat
      this.log.Dialog_Read_Visible = true
      if (typeof this.log.notes.users === 'string') {
        try {
          this.log.notes.users = JSON.parse(this.log.notes.users)
        } catch (e) {
          console.error('JSON parsing error:', e)
        }
      }
    },
    click_fun_edit (number, type, value) {
      let id = 0; if (this.log?.val?.data?.[0]?.id !== undefined) { id = this.log.val.data[0].id }
      if (type === 'EditLog') { number = this.log.val.data[0].number } else if (type === 'Del') { this.log.Dialog_Edit_Visible = false; this.log.Dialog_Read_Visible = false }

      this.$axios({
        method: 'post',
        url: '/notes/index/updata',
        data: { id: id, number: number, type: type, value: value }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.get_list()
          this.get_log(this.log.notes)
          this.$message.success(res.data.msg)
        }
      })
    },
    handleCurrentChange (val) {
      this.log.val.current_page = val
      this.get_log(this.log.notes)
    },
    EditUser (type, dat) {
      if (type === 'add') {
        if (this.log.notes.users[dat.uid] === undefined) {
          this.log.notes.users[dat.uid] = dat.name
          this.log.notes.users = { ...this.log.notes.users }
        }
      } else if (type === 'del') {
        delete this.log.notes.users[dat]
        this.log.notes.users = { ...this.log.notes.users }
      } else if (type === 'EditUsers') {
        this.$axios({
          method: 'post',
          url: '/notes/index/edit_users',
          data: { number: dat, type: type, value: this.log.notes.users }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(JSON.stringify(res.data.msg))
          } else {
            this.$message.success(res.data.msg)
          }
        })
        // console.log(dat, JSON.stringify(this.log.notes.users))
      }
    }
  }
}
</script>

<style lang="less" scoped>
  td{
      padding: 0px 0px 0px 0px
  }
  .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
  }
</style>
