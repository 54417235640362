<template>
  <div class="login-container">

    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules2"
      status-icon
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <!-- <img style="text-align: center;" src="../../../public/title.png" /> -->

      <el-form-item prop="username">
        <el-input v-model="ruleForm.username" type="text" auto-complete="off" placeholder="用户名" @keyup.enter.native="handleSubmit" />
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="ruleForm.password" type="password" auto-complete="off" placeholder="密码" @keyup.enter.native="handleSubmit" />
      </el-form-item>

      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" :loading="logining" @click="handleSubmit">登录</el-button>
      </el-form-item>
      <!-- <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2020098268号</a> -->
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      logining: false,
      ruleForm: {
        username: '',
        password: ''
      },
      rules2: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }]
      },
      checked: false
    }
  },
  methods: {
    handleSubmit (event) {
      this.$axios({
        method: 'post',
        url: '/login/index/login',
        data: {
          name: this.ruleForm.username, // 传接口参数
          pswd: this.ruleForm.password
        }
      }).then(res => {
        const _this = this
        if (res.data.status === 0) {
          _this.userToken = 'Bearer ' + res.data.token
          let url = window.sessionStorage.getItem('UrlRestore')
          window.sessionStorage.setItem('token', res.data.token)
          window.sessionStorage.setItem('user_uid', res.data.uid)
          window.sessionStorage.setItem('user_name', res.data.name)
          if (url === null) { url = '/home' }; _this.$router.push(url)
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        this.$message.error(error.message)
      })
    }
  }
}
</script>

<style scoped>
  .login-container {
    width: 100%;
    height: 100%;
  }

  .login-page {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin: 180px auto;
    width: 350px;
    padding: 35px 35px 15px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }

  label.el-checkbox.rememberme {
    margin: 0px 0px 15px;
    text-align: left;
  }
</style>
