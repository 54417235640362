<template>

  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/users/list' }">用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="2">
      <el-col :span="7"><el-input v-model="add_user.name" placeholder="用户名" prefix-icon="el-icon-user" /></el-col>
      <!-- <el-col :span="7"><el-input placeholder="电  话" prefix-icon="el-icon-phone" v-model="add_user.phone"></el-input></el-col> -->
      <el-col :span="7"><el-input v-model="add_user.pswd" placeholder="密  码" prefix-icon="el-icon-key" show-password /></el-col>
      <el-col :span="3"><el-button @click="add_user_fun">添加用户</el-button></el-col>
    </el-row>
    <br><br>

    <table v-if="list.data.length > 0" class="el-table" style="width:auto">
      <tr>
        <th style="text-align:center;">用户名</th>
        <template v-if="list.data[0].father">
          <th style="text-align:center;">路径</th>
        </template>
        <th style="text-align:center;">时间</th>
        <th style="text-align:center;">状态</th>
        <th style="text-align:center;" />
      </tr>
      <template v-for="(data, row) in list.data" :v-bind:key="row">
        <tr :key="row">
          <td style="padding:20px 20px 20px 20px">{{ data.name }}</td>
          <td v-if="data.father" style="padding:20px 20px 20px 20px">{{ data.father }}</td>
          <td style="padding:20px 20px 20px 20px">{{ data.time }}</td>
          <td style="padding:20px 20px 20px 20px">
            <el-switch
              v-model="data.state"
              active-value="enable"
              inactive-value="disable"
              @change="click_fun_state_enable('state', data.uid, data.state)"
            >
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </td>
          <td style="padding:20px 20px 20px 20px">
            <el-button type="primary" icon="el-icon-edit" :circle="true" @click="click_fun_edit(data.uid)" />
            <el-button type="primary" icon="el-icon-key" :circle="true" @click="click_fun_auth(data.uid)" />
            <el-button type="primary" icon="el-icon-delete" :circle="true" @click="click_fun_del(data.uid)" />
          </td>
        </tr>
      </template>
    </table>

    <div :v-if="list.data">
      <el-pagination
        hide-on-single-page
        :page-size="list.per_page"
        :page-count="list.last_page"
        @size-change="handleSizeChange"
        :current-page="list.current_page"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]">
      </el-pagination>
    </div>

  </div>

</template>

<script>
export default {
  data () {
    return {
      list: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      add_user: {
        name: '',
        pswd: '',
        phone: ''
      }
    }
  },
  created: function () {
    this.get_user_list()
  },
  methods: {
    // 获取
    get_user_list () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_list',
        data: { per_page: this.list.per_page, current_page: this.list.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data.list
        }
      })
    },
    handleSizeChange (val) {
      this.get_user_list(this.list.per_page = val)
    },
    handleCurrentChange (val) {
      this.get_user_list(this.list.current_page = val)
    },
    add_user_fun () {
      this.$axios({
        method: 'post',
        url: '/user/index/add_user',
        data: this.add_user
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.success('用户添加成功')
          this.get_user_list()
        }
      })
    },
    click_fun_edit (uid) {
      this.$router.push({ path: '/users/edit', query: { uid: uid } })
    },
    click_fun_auth (uid) {
      this.$router.push({ path: '/users/auth', query: { uid: uid } })
    },
    click_fun_state_enable (type, uid, value) { // 编辑用户信息
      this.$axios({
        method: 'post',
        url: '/user/index/user_info_edit',
        data: { uid: uid, type: type, value: value }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    click_fun_del (uid) {
      this.$confirm('确认删除该用户?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        this.$axios({
          method: 'post',
          url: '/user/index/del_user',
          data: { uid: uid }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.$message.success('用户删除成功')
            this.get_user_list()
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
