<template>
  <div>
    <el-page-header @back="$router.go(-1);" content="编辑"> </el-page-header>
    <br>

    <div style="width: 100%; height: 30px;">
      <el-link type="primary" style="margin-right: 20px;" @click="gys.Visible=true; get_supplier()">获取历史订单信息</el-link>
    </div>
    <br>

    <table id="purchase-table" class="el-table" style="width:100%">
      <tr>
        <td>需方</td>
        <td>供方</td>
      </tr>
      <tr>
        <td><el-input v-model="cg.demander.company" style="text-align:center" placeholder="公司名">bbb</el-input></td>
        <td><el-input v-model="cg.supplier.company" style="text-align:center" placeholder="公司名">ccc</el-input></td>
      </tr>
      <tr>
        <td><el-input v-model="cg.demander.name" style="text-align:center" placeholder="收货人">bbb</el-input></td>
        <td><el-input v-model="cg.supplier.name" style="text-align:center" placeholder="寄货人">ccc</el-input></td>
      </tr>
      <tr>
        <td><el-input v-model="cg.demander.tel" style="text-align:center" placeholder="电话">bbb</el-input></td>
        <td><el-input v-model="cg.supplier.tel" style="text-align:center" placeholder="电话">ccc</el-input></td>
      </tr>
      <tr>
        <td><el-input v-model="cg.demander.addr" style="text-align:center" placeholder="地址">bbb</el-input></td>
        <td><el-input v-model="cg.supplier.addr" style="text-align:center" placeholder="地址">ccc</el-input></td>
      </tr>
      <tr>
        <td colspan="2"><el-input v-model="cg.contract" style="text-align:center" type="textarea" rows="5" placeholder="合约">bbb</el-input></td>
      </tr>
    </table>

    <table id="purchase-list" class="el-table" style="width:100%">
      <tr>
        <th style="text-align:center">料号</th>
        <th style="text-align:left">商品</th>
        <th style="text-align:center">单价</th>
        <th style="text-align:center">数量</th>
        <th style="text-align:center">单位</th>
        <th style="text-align:center">交货日期</th>
        <th style="text-align:center">备注</th>
        <th style="text-align:center" />
      </tr>

      <template v-for="(data, row) in cg.list" :v-bind:key="row">
        <tr :key="row">
          <td style="width: 120px;">{{ data.sn }}</td>
          <td style="width: auto;">{{ data.model }}</td>
          <td style="width: 70px;"> <el-input v-model="data.price" placeholder="单价" /></td>
          <td style="width: 70px;"> <el-input v-model="data.num" placeholder="数量" /></td>
          <td style="width: 70px;"> <el-input v-model="data.unit" placeholder="单位" /></td>
          <td style="width: 70px;"><el-date-picker v-model="data.date" type="date" placeholder="交货日期" /></td>
          <td style="width: auto;"> <el-input v-model="data.remarks" placeholder="备注" /></td>
          <td style="width: 70px;"> <el-button icon="el-icon-delete" @click="click_fun_row_del(row)" /> </td>
        </tr>
      </template>
    </table>

    <br>
    <el-button @click="click_fun_add_show" type="primary" plain style="width: 100%;">添加商品</el-button>

    <br><br><br>
    <el-input v-model="input_log" type="textarea" rows="5" placeholder="备注" />
    <el-button @click="click_fun_submit" type="primary" plain style="width: 100%;">创建新订单</el-button>

    <el-dialog title="添加" :visible.sync="addVisible" width="70%">
      <div>
        <el-row>
          <el-col :span="15"><el-input v-model="screen" placeholder="查找" /></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_material">查找</el-button></el-col>
        </el-row>

        <table v-if="material.data.length > 0" class="el-table" style="width:100%">
          <tr>
            <th style="text-align:center; width:120px">编号</th>
            <th style="text-align:center; width:auto">型号</th>
            <th style="text-align:center; width:60px">库存</th>
            <th style="text-align:center; width:60px" />
          </tr>
          <template v-for="(data, row) in material.data" :v-bind:key="row">
            <tr :key="row">
              <td>{{ data.sn }}</td>
              <td>{{ data.model }}</td>
              <td style="text-align:center;">{{ data.sum }}</td>
              <td>
                <el-button icon="el-icon-check" size="mini" @click="click_fun_add(data.sn, data.model, data.unit)" />
              </td>
            </tr>
          </template>
        </table>

        <div :v-if="material.data">
          <el-pagination
            hide-on-single-page
            :page-size="material.per_page"
            :page-count="material.last_page"
            :current-page="material.current_page"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            @size-change="handleSizeChangeMaterial"
            @current-change="handleCurrentChangeMaterial"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog title="按历史添加供需信息" :visible.sync="gys.Visible" width="90%">
      <el-row>
        <el-col :span="15"><el-input v-model="gys.screen" placeholder="查找" /></el-col>
        <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_supplier()">查找</el-button></el-col>
      </el-row>
      <table v-if="gys.dd.data.length > 0" class="el-table" style="width:100%">
        <tr>
          <th style="text-align:center; width:auto">公司</th>
          <th style="text-align:center; width:auto">联系人</th>
          <th style="text-align:center; width:auto">电话</th>
          <th style="text-align:center; width:auto">地址</th>
          <th style="text-align:center; width:auto" />
        </tr>
        <template v-for="(data, row) in gys.dd.data" :v-bind:key="row">
          <tr :key="row">
            <td style="text-align:center;">{{ data.company1 }}</td>
            <td style="text-align:center;">{{ data.name1 }}</td>
            <td style="text-align:center;">{{ data.tel1 }}</td>
            <td style="text-align:center;">{{ data.addr1 }}</td>
            <td style="text-align:center;">
              <el-button icon="el-icon-check" size="mini" @click="click_fun_gys(row)" />
            </td>
          </tr>
        </template>
      </table>

      <div :v-if="material.data">
        <el-pagination
          hide-on-single-page
          :page-size="material.per_page"
          :page-count="material.last_page"
          :current-page="material.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChangeMaterial"
          @current-change="handleCurrentChangeMaterial"
        />
      </div>
    </el-dialog>
    <br><br><br><br><br>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cg: {
        list: [],
        title: '',
        contract: '',
        supplier: { // 供方
          company: '',
          name: '',
          tel: '',
          addr: ''
        },
        demander: { // 需方
          company: '',
          name: '',
          tel: '',
          addr: ''
        }
      },
      gys: {
        dd: {
          data: [],
          total: 1, // 总计
          per_page: 10, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        screen: '',
        Visible: false
      },
      material: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      screen: '',
      input_log: '',
      addVisible: false
    }
  },
  created: function () {
    const list = this.$route.query.list
    for (let a = 0; a < list.length; a++) {
      this.click_fun_add(list[a].sn, list[a].model, list[a].unit, list[a].num)
    }
  },
  methods: {
    get_material () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_list',
        data: { per_page: this.material.per_page, current_page: this.material.current_page, mode: 'normal', screen: this.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.material = res.data.data
        }
      })
    },
    get_supplier () {
      this.$axios({
        method: 'post',
        url: '/material/purchase/get_list',
        data: { per_page: this.material.per_page, current_page: this.material.current_page, screen: this.gys.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          var dt = res.data.data
          for (let a = 1; a < dt.data.length;) {
            if (dt.data[a].number === dt.data[a - 1].number) {
              dt.data.splice(a, 1)
            } else { a++ }
          }
          this.gys.dd = dt
        }
      })
    },
    click_fun_gys (row) {
      this.gys.Visible = false
      this.cg.contract = this.gys.dd.data[row].contract
      this.cg.supplier.tel = this.gys.dd.data[row].tel1
      this.cg.demander.tel = this.gys.dd.data[row].tel0
      this.cg.supplier.addr = this.gys.dd.data[row].addr1
      this.cg.demander.addr = this.gys.dd.data[row].addr0
      this.cg.supplier.name = this.gys.dd.data[row].name1
      this.cg.demander.name = this.gys.dd.data[row].name0
      this.cg.supplier.company = this.gys.dd.data[row].company1
      this.cg.demander.company = this.gys.dd.data[row].company0
    },
    click_fun_add_show () {
      this.get_material()
      this.addVisible = true
    },
    click_fun_add (sn, model, unit, num = '') {
      this.addVisible = false
      this.cg.list.push({ num: num, date: '', price: '', remarks: '', sn: sn, unit: unit, model: model })
      if (this.cg.list.length > 1) { this.cg.list[this.cg.list.length - 1].date = this.cg.list[0].date } else { this.cg.list[0].date = new Date().getTime() + 3600 * 1000 * 24 * 1 }
    },
    click_fun_submit () {
      if (this.cg.list.length === 0) { return this.$message.error('无数据不可以提交') }
      for (let a = 0; a < this.cg.list.length; a++) {
        if (this.cg.list[a].num === '') { return this.$message.error('数量输入值异常') }
        if (this.cg.list[a].unit === '') { return this.$message.error('单位输入值异常') }
        if (this.cg.list[a].date === '') { return this.$message.error('日期输入值异常') }
        if (this.cg.list[a].price === '') { return this.$message.error('单价输入值异常') }
      }
      if (this.cg.supplier.tel === '') { return this.$message.error('电话输入值异常') }
      if (this.cg.demander.tel === '') { return this.$message.error('电话输入值异常') }
      if (this.cg.supplier.addr === '') { return this.$message.error('地址输入值异常') }
      if (this.cg.demander.addr === '') { return this.$message.error('地址输入值异常') }
      if (this.cg.supplier.name === '') { return this.$message.error('姓名输入值异常') }
      if (this.cg.demander.name === '') { return this.$message.error('姓名输入值异常') }
      if (this.cg.supplier.company === '') { return this.$message.error('公司名输入值异常') }
      if (this.cg.demander.company === '') { return this.$message.error('公司名输入值异常') }
      this.$axios({
        method: 'post',
        url: '/material/purchase/add',
        data: { data: this.cg, log: this.input_log }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.success(res.data.msg)
          this.$router.push({ path: '/purchase/list' })
        }
      })
    },
    click_fun_row_del (row) {
      this.cg.list.splice(row, 1)
    },
    handleSizeChangeMaterial (val) {
      this.material.per_page = val
      this.get_material()
    },
    handleCurrentChangeMaterial (val) {
      this.material.current_page = val
      this.get_material()
    }
  }
}
</script>

<style lang="less" scoped>
td {
        padding:0px 0px 0px 0px
}
</style>
