<template>
  <div>
    <el-page-header content="时段设置" @back="$router.go(-1);" />
    <br>

    <div :v-if="didi_data">
      <span>姓名</span> <span style="margin-left: 20px;">{{ didi_data.name }}</span><br>
      <span>电话</span> <span style="margin-left: 20px;">{{ didi_data.mobile }}</span><br>
      <span>编号</span> <span style="margin-left: 20px;">{{ didi_data.userid }}</span><br><br>
    </div>

    <div :v-if="user_data.CheckTimePeriod">
      <div v-for="(item, index) in user_data.CheckTimePeriod" :key="index">
        <template>
          <el-row>
            <el-col :span="24">
              <!-- <el-time-picker style="width:120px" v-model="item.time_start" format="HH:mm:ss" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"></el-time-picker>
                    <el-time-picker style="width:120px" v-model="item.time_end"   format="HH:mm:ss" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}"></el-time-picker> -->
              <el-time-select v-model="item.time_start" style="width:120px" placeholder="起始时间" format="HH:mm:ss" size="medium" :picker-options="{ start: '00:00', step: '00:10', end: '24:00' }" />
              <el-time-select v-model="item.time_end" style="width:120px" placeholder="结束时间" format="HH:mm:ss" size="medium" :picker-options="{ start: '00:00', step: '00:10', end: '24:00' }" />
              &emsp; <el-button type="primary" size="mini" icon="el-icon-plus" :circle="true" @click="click_fun_set_TimePeriod(index, 1)" />
              &emsp; <el-button type="primary" size="mini" icon="el-icon-minus" :circle="true" @click="click_fun_set_TimePeriod(index, 0)" />
            </el-col>
          </el-row>
        </template>
      </div>
    </div>
    <el-button style="margin-left: 0px; width: 240px;" @click="button_set">修改</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user_data: [],
      didi_data: []
    }
  },
  created: function () {
    this.get_user_info()
  },
  methods: {
    get_user_info () {
      this.$axios({
        method: 'post',
        url: '/dingtalk/index/get_user_info',
        data: { userid: this.$route.query.userid }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.didi_data = res.data.data.info.result
          this.user_data = JSON.parse(res.data.data.user[0].data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    button_set () {
      for (let i = 0; i < this.user_data.CheckTimePeriod.length; i++) {
        if (this.user_data.CheckTimePeriod[i].time_end.length === 5) { this.user_data.CheckTimePeriod[i].time_end += ':00' }
        if (this.user_data.CheckTimePeriod[i].time_start.length === 5) { this.user_data.CheckTimePeriod[i].time_start += ':00' }
        // this.user_data.CheckTimePeriod[i].time_end = new Date('2000-01-01 ' + this.user_data.CheckTimePeriod[i].time_end).getTime().toLocaleString()
        // this.user_data.CheckTimePeriod[i].time_end = this.FormDate(this.user_data.CheckTimePeriod[i].time_end).t2
        // this.user_data.CheckTimePeriod[i].time_start = this.FormDate(this.user_data.CheckTimePeriod[i].time_start).t2
      }
      this.$axios({
        method: 'post',
        url: '/dingtalk/index/set_user_info',
        data: { user_data: this.user_data, userid: this.$route.query.userid }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.get_user_info()
          this.$message('完成修改')
        } else { this.$message.error(res.data.msg) }
      })
    },
    click_fun_set_TimePeriod (index, set) {
      if (set > 0) {
        if (this.user_data.CheckTimePeriod.length < 3) {
          this.user_data.CheckTimePeriod.splice(index + 1, 0, {
            dataItem: 'time' + (index + 1), time_start: '08:00', time_end: '08:30'
          })
        }
      } else if (this.user_data.CheckTimePeriod.length > 1) {
        this.user_data.CheckTimePeriod.splice(index, 2)
      }
    }
  }
}
</script>

    <style lang="less" scoped>

</style>
