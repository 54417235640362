<template>
  <el-container class="home-container" style="min-width: 800px;">
    <el-header>
      <!-- <span @click="fun_ToHome" style="padding: 5px 20px 5px 20px">ERP系统</span> -->
      <span @click="fun_ToHome" style="padding: 5px 20px 5px 20px">狗熊机器人ERP系统</span>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">{{ user_name }}<i class="el-icon-arrow-down el-icon--right" /></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit">编辑用户</el-dropdown-item>
          <el-dropdown-item command="logout">注销用户</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-header>
    <el-container>
      <!-- 侧边栏区域 -->
      <div id="cDivSidebar">
        <div style="display:inline-block;">
          <div id="cDivRetract" @click="fun_Collapse">
            <template v-if="isCollapse"> <i class="el-icon-arrow-right"></i>  </template>
            <template v-else>            <i class="el-icon-arrow-left" ></i>  </template>
          </div>
          <template v-if="menuList.data">
            <el-menu class="el-menu-vertical-demo" text-color="#fff" background-color="#333744" active-text-color="#ffd04b" :router="true" :collapse="isCollapse">
              <el-submenu v-for="arry0 in menuList.data[0].data" :key="arry0.id" :index="arry0.id + '1'">
                <template slot="title">
                  <i :class="arry0.icon"></i>
                  <span slot="title">{{ arry0.authName }}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item style="min-width:150px" v-for="arry1 in arry0.children" :key="arry1.id" :index="arry1.path">{{ arry1.authName }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </template>
        </div>
      </div>
      <!-- 主视图 -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data () {
    return {
      menuList: [],
      user_name: '',
      isCollapse: false
    }
  },
  created () {
    this.getMenuList()
    this.user_name = window.sessionStorage.getItem('user_name', '')
    if (window.sessionStorage.getItem('Home_isCollapse', false) === 'true') { this.isCollapse = true } else { this.isCollapse = false }
  },
  methods: {
    // 获取所有的菜单
    getMenuList () {
      this.$axios({
        method: 'post',
        url: '/index/index/get_menus'
      }).then(res => {
        this.menuList = res
        window.sessionStorage.setItem('Home_menuList', JSON.stringify(this.menuList))
      })
      try { this.menuList = JSON.parse(window.sessionStorage.getItem('Home_menuList')) } catch {}
    },
    handleCommand (command) {
      if (command === 'logout') {
        window.sessionStorage.setItem('token', '')
        this.$router.push('/login')
      }
      if (command === 'edit') {
        this.$router.push('/users/selfinfo')
      }
    },
    fun_ToHome () {
      this.$router.push('/home/welcome')
    },
    fun_Collapse () {
      if (this.isCollapse) { this.isCollapse = false } else { this.isCollapse = true }; window.sessionStorage.setItem('Home_isCollapse', this.isCollapse)
    }
  }
}
</script>

<style lang="less" scoped>
  i {
    color: #fff;
  }
  #cDivSidebar {
    display: flex;
    height: inherit;
    background-color: #ffff;
  }
  #cDivRetract {
    display: flex;
    justify-content: center;
    background-color: #09b9b9;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
  }
  .el-header{
    color: #fff;
    display: flex;
    font-size: 20px;
    padding-left: 0px;
    align-items: center;
    background-color: #373d41;
    justify-content: space-between;
  }
  .el-aside{
    background-color: #333744;
  }
  .el-main{
    border-right:0px;
    background-color: #f3f3f3;
  }
  .el-menu{
    border: 0;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #FFFFFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>
