<template>

  <div>
    <el-page-header @back="$router.go(-1);" content="编辑"> </el-page-header>
    <br>

    <div v-if="list.data.length > 0">
      <el-row><el-col :span="24"><label>CG{{ list.data[0].number }}</label></el-col></el-row>
      <br>
      <el-card class="box-card" style="width: 95%;">
        <table style="width: auto;" class="el-table">
          <tr>
            <th></th>
            <th>需方</th>
            <th>供方</th>
          </tr>
          <tr>
            <td style="padding-right: 20px;">公司</td>
            <td style="padding-right: 30px;" @click="edit.title='需方公司名修改'; edit.type='company0'; edit.row = -1; edit.visible=true;">{{list.data[0].company0}}</td>
            <td style="padding-right: 30px;" @click="edit.title='供方公司名修改'; edit.type='company1'; edit.row = -1; edit.visible=true;">{{list.data[0].company1}}</td>
          </tr>
          <tr>
            <td>姓名</td>
            <td @click="edit.title='需方联系人修改'; edit.type='name0'; edit.row = -1; edit.visible=true;">{{list.data[0].name0}}</td>
            <td @click="edit.title='供方联系人修改'; edit.type='name1'; edit.row = -1; edit.visible=true;">{{list.data[0].name1}}</td>
          </tr>
          <tr>
            <td>地址</td>
            <td @click="edit.title='需方地址修改'; edit.type='addr0'; edit.row = -1; edit.visible=true;">{{list.data[0].addr0}}</td>
            <td @click="edit.title='供方地址修改'; edit.type='addr1'; edit.row = -1; edit.visible=true;">{{list.data[0].addr1}}</td>
          </tr>
          <tr>
            <td>电话</td>
            <td @click="edit.title='需方电话修改'; edit.type='tel0'; edit.row = -1; edit.visible=true;">{{list.data[0].tel0}}</td>
            <td @click="edit.title='供方电话修改'; edit.type='tel1'; edit.row = -1; edit.visible=true;">{{list.data[0].tel1}}</td>
          </tr>
        </table>
      </el-card>
      <br>
    </div>

    <div v-if="list.data.length > 0">
      <el-row><el-col :span="24">
        <pre @click="edit.title='合约修改'; edit.type='contract'; edit.row = -1; edit.value=list.data[0].contract; edit.visible=true;">{{ list.data[0].contract }}</pre>
      </el-col></el-row>
      <br>
    </div>

    <el-card class="box-card" style="width: 95%;">
      <div>
        <table v-if="list.data.length > 0" class="el-table" style="width:auto">
          <tr>
            <th style="text-align:center; width:120px">料号</th>
            <th style="text-align:left; width:auto;">型号</th>
            <th style="text-align:center; width: 50px">采购量</th>
            <th style="text-align:center; width: 50px">入库量</th>
            <th style="text-align:center; width: 60px">单位</th>
            <th style="text-align:center; width: 50px">单价</th>
            <th style="text-align:center; width: 150px">交货日期</th>
            <th style="text-align:center; width: auto">备注</th>
            <th style="text-align:center; width: 60px" />
          </tr>
          <template v-for="(data, row) in list.data" :v-bind:key="row">
            <tr :key="row">
              <td style="text-align:center;"><router-link :to="{ path: '/material/inout', query:{sn: data.sn}}">{{ data.sn }}</router-link></td>
              <td style="text-align:left;">{{ data.model }}</td>
              <td style="text-align:center;" @click="edit.title='采购量修改'; edit.type='num'; edit.row=row; edit.visible=true;">    {{ data.num }}</td>
              <td style="text-align:center;" @click="edit.title='入库'; edit.type='in_num'; edit.row=row; edit.visible=true; edit.value=data.num-data.in_num"> {{ data.in_num }}</td>
              <td style="text-align:center;" @click="edit.title='单位修改'; edit.type='unit'; edit.row=row; edit.visible=true;">   {{ data.unit }}</td>
              <td style="text-align:center;" @click="edit.title='单价修改'; edit.type='price'; edit.row=row; edit.visible=true;">  {{ data.price }}</td>
              <td style="text-align:center;" @click="edit.title='时间修改'; edit.type='time'; edit.row=row; edit.visible=true;">  {{ data.time }}</td>
              <td style="text-align:center;" @click="edit.title='备注修改'; edit.type='remarks'; edit.row=row; edit.visible=true;">{{ data.remarks }}</td>
              <td><el-button icon="el-icon-delete" size="mini" style="margin-left: 20px;" @click="click_fun_edit('del', 1, row)" /></td>
            </tr>
          </template>
        </table>
        <el-row>
          <el-col :span="23"> <el-button type="success" plain style="width:100%" @click="addVisible=true; get_material()">追加一行</el-button></el-col>
        </el-row>
      </div>
    </el-card>

    <br><br>
    <el-card class="box-card" style="width: 95%;">
      <el-row>
        <el-col :span="23"><el-input v-model="edit.log" type="textarea" :rows="10" placeholder="备注信息" /></el-col>
        </el-row>
        <el-row>
          <el-col :span="23"> <el-button type="success" plain style="width:100%" @click="click_fun_edit('log', edit.log)">提交备注</el-button></el-col>
        </el-row>
    </el-card>

    <br>
    <el-card class="box-card" style="width: 95%;">
      <table v-if="log.data.length > 0" class="el-table" style="width:100%">
        <tr>
          <th style="text-align:center; width:150px">时间</th>
          <th style="text-align:center; width:auto">用户</th>
          <th style="text-align:center; width:150px">编号</th>
          <th style="text-align:left; width:auto">内容</th>
        </tr>
        <template v-for="(data, row) in log.data" :v-bind:key="row">
          <tr :key="row">
            <td style="text-align:center;">{{ data.time }}</td>
            <td style="text-align:center;">{{ data.uid }} / {{ data.name }}</td>
            <td style="text-align:center;">{{ data.sn }}</td>
            <td style="text-align:left;">{{ data.log }}</td>
          </tr>
        </template>
      </table>

      <div :v-if="log.data">
        <el-pagination
          hide-on-single-page
          :page-size="log.per_page"
          :page-count="log.last_page"
          :current-page="log.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChangeLog"
          @current-change="handleCurrentChangeLog"
        />
      </div>
    </el-card>

    <!-- 弹出窗 -->
    <el-dialog v-if="list.data.length > 0" :title="edit.title" :visible.sync="edit.visible" width="70%" @close="get_list">
      <div>
        <el-row>
          <template v-if="edit.type == 'in_num'">
            <el-col :span="20"><el-input v-model="edit.value"/></el-col>
            <el-col :span="2" ><el-button icon="el-icon-edit" @click="click_fun_edit(edit.type, edit.value, edit.row)" /></el-col>
          </template>

          <template v-else-if="edit.type == 'contract'">
            <el-col :span="24"><el-input type="textarea" rows="10" v-model="edit.value"/></el-col>
            <el-col :span="24" ><el-button style="width: 100%;" type="primary" @click="click_fun_edit(edit.type, edit.value, edit.row)">提交</el-button></el-col>
          </template>

          <template v-else-if="edit.row >= 0">
            <el-col :span="20"><el-input v-model="list.data[edit.row][edit.type]" /></el-col>
            <el-col :span="2" ><el-button icon="el-icon-edit" @click="click_fun_edit(edit.type, list.data[edit.row][edit.type], edit.row)" /></el-col>
          </template>

          <template v-else>
            <el-col :span="20"><el-input v-model="list.data[0][edit.type]" /></el-col>
            <el-col :span="2" ><el-button icon="el-icon-edit" @click="click_fun_edit(edit.type, list.data[0][edit.type], edit.row)" /></el-col>
          </template>

        </el-row>
      </div>
    </el-dialog>

    <el-dialog title="添加" :visible.sync="addVisible" width="95%" @close="get_list">
      <div>
        <el-row>
          <el-col :span="15"><el-input v-model="material.screen" placeholder="查找" /></el-col>
          <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_material">查找</el-button></el-col>
        </el-row>

        <table v-if="material.data.length > 0" class="el-table" style="width:100%">
          <tr>
            <th style="text-align:center; width:120px">编号</th>
            <th style="text-align:center; width:auto">型号</th>
            <th style="text-align:center; width:100px">单价</th>
            <th style="text-align:center; width:100px">数量</th>
            <th style="text-align:center; width:60px">单位</th>
            <th style="text-align:center; width:120px">交货日期</th>
            <th style="text-align:center; width:auto">备注</th>
            <th style="text-align:center; width:70px" />
          </tr>
          <template v-for="(data, row) in material.data" :v-bind:key="row">
            <tr :key="row">
              <td>{{ data.sn }}</td>
              <td>{{ data.model }}</td>
              <td><el-input v-model="data.price" placeholder="单价" /></td>
              <td><el-input v-model="data.num" placeholder="数量" /></td>
              <td><el-input v-model="data.unit" placeholder="单位" /></td>
              <td><el-input v-model="data.date" placeholder="交货日期" /></td>
              <td><el-input v-model="data.remarks" placeholder="备注" /></td>
              <td><el-button icon="el-icon-plus" @click="click_fun_add(row)"/></td>
            </tr>
          </template>
        </table>

        <div :v-if="material.data">
          <el-pagination
            hide-on-single-page
            :page-size="material.per_page"
            :page-count="material.last_page"
            :current-page="material.current_page"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            @size-change="handleSizeChangeMaterial"
            @current-change="handleCurrentChangeMaterial"
          />
        </div>
      </div>
    </el-dialog>
    <br><br><br><br><br>
  </div>

</template>

<script>
export default {
  data () {
    return {
      list: {
        data: []
      },
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      material: {
        data: [],
        screen: '',
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      screen: '',
      addVisible: false,
      number: this.$route.query.number,
      edit: { title: '', type: '', row: 0, value: 0, log: '', visible: false }
    }
  },
  created: function () {
    this.get_log()
    this.get_list()
  },
  methods: {
    get_list () {
      this.$axios({
        method: 'post',
        url: '/material/purchase/get_list',
        data: {
          screen: '',
          per_page: 10000,
          current_page: 1,
          number: this.number
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    get_material () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_list',
        data: { per_page: this.material.per_page, current_page: this.material.current_page, mode: 'normal', screen: this.material.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          const dt = res.data.data
          for (let a = 0; a < dt.data.length; a++) {
            dt.data[a].num = ''
            dt.data[a].price = ''
            dt.data[a].remarks = ''
            if (this.list.data.length > 1) {
              dt.data[a].date = this.list.data[0].time
            } else {
              dt.data[a].date = new Date().getTime() + 3600 * 1000 * 24 * 1
            }
          }
          this.material = dt
        }
      })
    },
    click_fun_add (row) {
      if (this.material.data[row].num === '') { return this.$message.error('数量输入值异常') }
      if (this.material.data[row].unit === '') { return this.$message.error('单位输入值异常') }
      if (this.material.data[row].date === '') { return this.$message.error('日期输入值异常') }
      if (this.material.data[row].price === '') { return this.$message.error('单价输入值异常') }
      this.$axios({
        method: 'post',
        url: '/material/purchase/add',
        data: { number: this.number, data: this.material.data[row], log: '' }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.get_log()
          this.$message.success(res.data.msg)
        }
      })
    },
    click_fun_edit (mode, value, row) {
      this.$confirm('确认修改?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        let id = -1
        if (row !== undefined && row >= 0) {
          id = this.list.data[row].id
        }
        this.$axios({
          method: 'post',
          url: '/material/purchase/edit',
          data: {
            id: id,
            type: mode,
            value: value,
            number: this.number
          }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.get_log()
            this.get_list()
            this.edit.visible = false
          }
        })
      })
    },
    get_log () {
      this.$axios({
        method: 'post',
        url: '/material/purchase/get_log',
        data: { number: this.number, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
        } else {
          this.log = res.data.data
        }
      })
    },
    handleSizeChangeLog (val) {
      this.log.per_page = val
      this.get_log()
    },
    handleCurrentChangeLog (val) {
      this.log.current_page = val
      this.get_log()
    },
    handleSizeChangeMaterial (val) {
      this.material.per_page = val
      this.get_material()
    },
    handleCurrentChangeMaterial (val) {
      this.material.current_page = val
      this.get_material()
    }
  }
}
</script>

<style lang="less" scoped>
td{
    padding:0px 0px 0px 0px
}
</style>
