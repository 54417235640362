<template>

  <div>
    <!-- {{details.data}}---{{account}} -->

    <br><br>
    <div v-if="details.data.length == 1">
      <el-row>
        <el-col :span="1"> <label>单号: </label> </el-col>
        <el-col :span="5"> <label>{{details.data[0].number}} </label> </el-col>
      </el-row>

      <el-row>
        <el-col :span="1"> <label>帐户: </label> </el-col>
        <el-col :span="5">
          <el-select v-model="details.data[0].account" placeholder="请选择">
            <el-option
              v-for="item in account"
              :key="item.number"
              :label="item.account"
              :value="item.account">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="2"> <el-button @click="bills_edit(details.data[0].id, 'account', details.data[0].account)">修改</el-button> </el-col>
      </el-row>

      <el-row>
        <el-col :span="1"> <label>时间: </label> </el-col>
        <el-col :span="5">
          <el-date-picker v-model="details.data[0].trading_time" type="datetime"></el-date-picker>
        </el-col>
        <el-col :span="2"> <el-button @click="bills_edit(details.data[0].id, 'trading_time', details.data[0].trading_time)">修改</el-button> </el-col>
      </el-row>

      <el-row>
        <el-col :span="1"> <label>金额: </label> </el-col>
        <el-col :span="5">
          <el-input v-model="details.data[0].amounts"></el-input>
        </el-col>
        <el-col :span="2"> <el-button @click="bills_edit(details.data[0].id, 'amounts', details.data[0].amounts)">修改</el-button> </el-col>
      </el-row>

      <el-row>
        <el-col :span="1"> <label>用途: </label> </el-col>
        <el-col :span="20">
          <el-input type="textarea" :rows="2" v-model="details.data[0].describe"></el-input>
        </el-col>
        <el-col :span="2"> <el-button @click="bills_edit(details.data[0].id, 'describe', details.data[0].describe)">修改</el-button> </el-col>
      </el-row>

    </div>

    <br><br><br>
    <table v-if="log.data.length > 0" class="el-table" >
      <tr>
        <th colspan="1">序号</th>
        <th colspan="1">时间</th>
        <th colspan="1">用户</th>
        <th colspan="1">内容</th>
      </tr>

      <template v-for="(data, row1) in log.data" :v-bind:key="row1">
        <tr :key="row1">
          <td style="padding:5px 20px 5px 20px">{{ row1+1 }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data.time }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data.user }}({{data.uid}})</td>
          <td style="padding:5px 20px 5px 20px">{{ data.log }}</td>
        </tr>
      </template>
    </table>
    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <br><br><br>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      account: [],
      details: {},
      number: this.$route.query.number
    }
  },
  created: function () {
    this.bills_get()
    this.account_get()
    this.bills_log_get()
  },
  methods: {
    bills_get () {
      this.$axios({
        method: 'post',
        url: '/finance/index/bills_get',
        data: { number: this.number, per_page: 10 }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.details = res.data.data
        }
      })
    },
    bills_log_get () {
      this.$axios({
        method: 'post',
        url: '/finance/index/bills_log_get',
        data: { number: this.number, per_page: this.log.per_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    account_get () {
      this.$axios({
        method: 'post',
        url: '/finance/index/account_get'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.account = res.data.data
        }
      })
    },
    bills_edit (id, key, value) {
      if (key === 'account') {
        for (let i = 0; i < this.account.length; i++) {
          if (this.account[i].account === value) {
            value = this.account[i].number
            break
          }
        }
      }
      this.$axios({
        method: 'post',
        url: '/finance/index/bills_edit',
        data: { id: id, key: key, value: value }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bills_log_get()
        }
      })
    },
    handleSizeChange (val) {
      this.log.per_page = val
      this.bills_get()
    },
    handleCurrentChange (val) {
      this.log.current_page = val
      this.bills_get()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
