<template>

  <div>
    <el-card class="box-card">
      <el-row>
        <el-col :span="4"><router-link to="type"><el-button size="mini" round>添加新型号</el-button></router-link></el-col>
        <el-col :span="4"><router-link to="add"><el-button size="mini" round>添加新物料</el-button></router-link></el-col>
        <el-col :span="4"><el-button size="mini" round @click="click_list_show_mode">{{ wl.show_mode }}</el-button></el-col>
      </el-row>
    </el-card>
    <br>

    <el-row>
      <el-col :span="15"><el-input v-model="wl.screen" placeholder="查找" /></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_list">查找</el-button></el-col>
    </el-row>

    <table v-if="wl.list.data.length > 0" class="el-table" style="width:auto">
      <tr>
        <th style="text-align:center;"></th>
        <th style="text-align:center;">编号</th>
        <th style="text-align:center;">类型</th>
        <th style="text-align:center;">型号</th>
        <th style="text-align:center;">数量</th>
        <th style="text-align:center;">单位</th>
        <th style="text-align:center;" />
      </tr>
      <template v-for="(data, row) in wl.list.data" :v-bind:key="row">
        <tr :key="row">
          <td style="padding:5px 20px 5px 20px">{{ row+1 }}</td>
          <td v-if="data.del == 0" style="padding:5px 20px 5px 20px" @click="click_fun_edit(row)"><el-link>{{ data.sn }}</el-link></td>
          <td v-if="data.del != 0" style="padding:5px 20px 5px 20px;" @click="click_fun_edit(row)"><el-link style="color: rgb(172, 180, 180);">{{ data.sn }}</el-link></td>
          <td v-if="data.del == 0" style="padding:5px 20px 5px 20px">{{ data.type }}</td>
          <td v-if="data.del == 0" style="padding:5px 20px 5px 20px">{{ data.model }}</td>
          <td v-if="data.del != 0" style="padding:5px 20px 5px 20px; color: rgb(172, 180, 180);">{{ data.model }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data.sum }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data.unit }}</td>
          <td style="padding:0px 20px 5px 0px">
            <el-button size="mini" icon="el-icon-edit" @click="click_fun_inout(row)" />
          </td>
          <td style="padding:0px 20px 5px 0px">
            <el-button size="mini" icon="el-icon-document" @click="click_fun_file(row)" />
          </td>
        </tr>
      </template>
    </table>
    <div :v-if="wl.list.data">
      <el-pagination
        hide-on-single-page
        :page-size="wl.list.per_page"
        :page-count="wl.list.last_page"
        :current-page="wl.list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      wl: {
        list: {
          data: [],
          total: 1, // 总计
          per_page: 20, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        screen: '',
        show_mode: '显示有效物料'
      }
    }
  },
  created: function () {
    let temp = localStorage.getItem('this.wl'); if (temp !== null && temp !== '' && temp.length > 0) { this.wl = JSON.parse(temp) }
    temp = localStorage.getItem('show_mode'); if (temp !== null && temp !== '' && temp.length > 0) { this.wl.show_mode = temp }
    this.get_list()
  },
  methods: {
    // 获取
    get_list () {
      let show = 'normal'
      if (this.wl.show_mode === '显示删除物料') { show = 'del' }
      this.$axios({
        method: 'post',
        url: '/material/index/get_list',
        data: { per_page: this.wl.list.per_page, current_page: this.wl.list.current_page, mode: show, screen: this.wl.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.wl.list = res.data.data
          localStorage.setItem('this.wl', JSON.stringify(this.wl))
        }
      })
    },
    click_fun_edit (row) {
      this.$router.push({ path: '/material/edit', query: { sn: this.wl.list.data[row].sn } })
    },
    click_fun_file (row) {
      this.$router.push({ path: '/material/file', query: { sn: this.wl.list.data[row].sn } })
    },
    click_fun_inout (row) {
      this.$router.push({ path: '/material/inout', query: { sn: this.wl.list.data[row].sn } })
    },
    click_list_show_mode () {
      if (this.wl.show_mode === '显示有效物料') { this.wl.show_mode = '显示删除物料' } else { this.wl.show_mode = '显示有效物料' }
      localStorage.setItem('show_mode', this.wl.show_mode)
      this.get_list()
    },
    handleSizeChange (val) {
      this.wl.list.per_page = val
      this.get_list()
    },
    handleCurrentChange (val) {
      this.wl.list.current_page = val
      this.get_list()
    }
  }
}
</script>

<style lang="less" scoped>

</style>
