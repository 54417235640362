<template>
  <div>
    <div style="width: 100%; height: 30px;">
      <el-link type="primary" style="margin-right: 20px;" @click="click_fun_add">创建工单</el-link>
    </div>
    <br>

      <table v-if="list.data.length > 0" class="el-table" >
      <tr>
        <th >序号</th>
        <th >单号</th>
        <th >时间</th>
        <th >uid</th>
        <th >用户</th>
        <!-- <th >路径</th> -->
        <th >料号</th>
        <th >型号</th>
        <th >总计</th>
        <th >描述</th>
      </tr>

      <template v-for="(data1, row1) in list.data" :v-bind:key="row1">
        <tr :key="row1">
          <td style="padding:5px 20px 5px 20px">{{ row1+1 }}</td>
          <td style="padding:5px 20px 5px 20px" @click="click_fun_edit(data1.number, data1.sn)"><el-link>{{ data1.number }}</el-link></td>
          <td style="padding:5px 20px 5px 20px">{{ data1.time }}</td>
          <td style="padding:5px 20px 5px 20px">{{data1.uid}}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.user}}</td>
          <!-- <td style="padding:5px 20px 5px 20px">{{ data1.path }}</td> -->
          <td style="padding:5px 20px 5px 20px">{{ data1.sn }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.model }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.sum }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.describe }}</td>
        </tr>
      </template>
    </table>
    <div :v-if="list.data">
      <el-pagination
        hide-on-single-page
        :page-size="list.per_page"
        :page-count="list.last_page"
        :current-page="list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <br><br><br>

  </div>
</template>

<script>
export default {
  data () {
    return {
      screen: '',
      list: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      }
    }
  },
  created: function () {
    this.get_list()
  },
  methods: {
    click_fun_add () {
      this.$router.push({ path: '/produce/add' })
    },
    get_list (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/produce/index/get_produce_list',
        data: { per_page: this.list.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    click_fun_edit (number, sn) {
      this.$router.push({ path: '/produce/works', query: { number: number, sn: sn } })
    },
    handleSizeChange (val) {
      this.get_list(val)
    },
    handleCurrentChange (val) {
      this.get_list(val)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
