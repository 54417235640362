<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/users/list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/users/auth' }">用户授权</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="width: auto;">
      <!-- 权限配置 -->
      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">UID:</label></el-col>
        <el-col :span="10"><label class="el-form-item__label">{{ user_data.uid }}</label></el-col>
      </el-row>
      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">用户名:</label></el-col>
        <el-col :span="10"><label class="el-form-item__label">{{ user_data.name }}</label></el-col>
      </el-row>
      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">创建时间:</label></el-col>
        <el-col :span="10"><label class="el-form-item__label">{{ user_data.time }}</label></el-col>
      </el-row>

      <el-card class="box-card">
        <table v-show="tableData.length > 0" class="" style="width:auto">
          <tr>
            <th style="text-align:center;">功能</th>
            <th style="text-align:center;">内容</th>
            <th style="text-align:center;">状态</th>
          </tr>
          <template v-for="(data, row) in tableData" :v-bind:key="row">
            <tr :key="row">
              <td v-if="row == 0 || tableData[row].app_menu != tableData[row-1].app_menu" style="padding-left:10px; padding-right:10px;" :rowspan="data.app_menu_row_span">{{ data.app_menu }}</td>
              <td v-if="data.attribute == 'main' && (row == 0 || tableData[row].attribute_menu != tableData[row-1].attribute_menu)" style="padding-left:10px; padding-right:50px;" :rowspan="data.attribute_menu_row_span">{{ data.attribute_menu }}</td>
              <td v-if="data.attribute != 'main' && (row == 0 || tableData[row].attribute_menu != tableData[row-1].attribute_menu)" style="padding-left:10px; padding-right:50px;" :rowspan="data.attribute_menu_row_span">-----{{ data.attribute_menu }}</td>
              <td style="padding-left:20px; padding-right:20px;">
                <el-switch
                  v-model="data.attribute_value"
                  active-value="1"
                  inactive-value="0"
                  @change="click_fun_auth_edit(data.url, data.attribute_value)"
                >
                  active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
              </td>
            </tr>
          </template>
        </table>
      </el-card>

    </div>

    <div :v-if="log.data">
      <el-card class="box-card">
        <el-table :data="log.data">
          <el-table-column prop="time" label="时间" width="170px" />
          <el-table-column prop="name" label="用户" width="100px" />
          <el-table-column prop="uid" label="uid" width="100px" />
          <el-table-column prop="log" label="内容" width="auto" />
        </el-table>
      </el-card>

      <div :v-if="log.data">
        <el-card class="box-card">
          <el-pagination
            hide-on-single-page
            :page-size="log.per_page"
            :page-count="log.last_page"
            :current-page="log.current_page"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-card>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 5, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      user_data: [],
      tableData: [],
      user_auth: []
    }
  },
  created: function () {
    this.get_user_log()
    this.get_user_info()
  },
  methods: {
    get_user_info () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_info',
        data: { uid: this.$route.query.uid }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.user_data = res.data.data[0]
          this.user_auth = JSON.parse(res.data.data[0].auth)
          const obj = JSON.parse(res.data.data[0].auth)
          const temp = []

          for (var KeyApp in obj) { // 应用
            for (var KeyControl in obj[KeyApp]) { // 控制器
              if (KeyControl !== 'describe') {
                for (var KeyFunc in obj[KeyApp][KeyControl]) { // 方法
                  if (KeyFunc !== 'describe') {
                    for (var KeyAttribute in obj[KeyApp][KeyControl][KeyFunc]) { // 属性
                      if (KeyFunc !== 'describe') {
                        temp.push({
                          app: KeyApp, //
                          control: KeyControl, //
                          func: KeyFunc, //
                          attribute: KeyAttribute,
                          app_menu: obj[KeyApp].describe.menu,
                          control_menu: obj[KeyApp][KeyControl].describe.menu,
                          attribute_menu: obj[KeyApp][KeyControl][KeyFunc][KeyAttribute].describe.menu,
                          attribute_value: obj[KeyApp][KeyControl][KeyFunc][KeyAttribute].enable + '',
                          url: KeyApp + '/' + KeyControl + '/' + KeyFunc + '/' + KeyAttribute
                        })
                      }
                    }
                  }
                }
              }
            }
          }

          for (let a = 0; a < temp.length; a++) {
            temp[a].app_menu_row_span = 0
            temp[a].control_menu_row_span = 0
            temp[a].function_menu_row_span = 0
            for (let b = 0; b < temp.length; b++) {
              if (temp[a].app_menu === temp[b].app_menu) {
                temp[a].app_menu_row_span++
                if (temp[a].control_menu === temp[b].control_menu) {
                  temp[a].control_menu_row_span++
                  if (temp[a].function_menu === temp[b].function_menu) {
                    temp[a].function_menu_row_span++
                  }
                }
              }
            }
          }
          this.tableData = temp
        } else {
          // this.$message.error(res.data.msg)
        }
      })
    },
    get_user_log () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_log',
        data: { uid: this.$route.query.uid, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.log = res.data.data
        } else {
          // this.$message.error(res.data.msg)
        }
      })
    },
    handleSizeChange (val) {
      this.get_user_log(this.log.per_page = val)
    },
    handleCurrentChange (val) {
      this.get_user_log(this.log.current_page = val)
    },
    click_fun_auth_edit (url, value) { // 编辑用户权限
      url = url.split('/')
      this.$axios({
        method: 'post',
        url: '/user/index/user_auth_edit',
        data: { uid: this.$route.query.uid, app: url[0], control: url[1], fun: url[2], attribute: url[3], value: value }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
        this.get_user_log()
        this.get_user_info()
      })
    }
  }
}
</script>

<style lang="less" scoped>
table {
  vertical-align:middle;
  border:1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  padding: 0;
  height: 35px;
  vertical-align:middle;
  border:1px solid #ddd;
}
th {
  padding: 0;
  text-align:center;
  vertical-align:middle;
  border:1px solid #ddd;
}
</style>
