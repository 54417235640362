import Vue from 'vue'

// 全部导入
import ElementUI from 'element-ui' // 导入 elementUI
import 'element-ui/lib/theme-chalk/index.css' // 导入 css
Vue.use(ElementUI)

// 按需导入
// import {
//   Input, Button, Form, FormItem, Container, Header, Aside,
//   Main, Menu, Submenu, MenuItemGroup, MenuItem
// } from 'element-ui'

// Vue.use(Form)
// Vue.use(Input)
// Vue.use(Button)
// Vue.use(FormItem)

// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Container)

// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(MenuItemGroup)
