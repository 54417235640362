<template>

  <div>
    <el-page-header content="编辑" @back="$router.go(-1);" />
    <br><br>

    <el-row>
      <el-col :span="3"><label class="el-form-item__label">{{ data.sn }}</label></el-col>
    </el-row>

    <el-row>
      <el-col :span="20"><el-input v-model="edit_log" type="textarea" placeholder="原因" /></el-col>
    </el-row>
    <el-row>
      <el-col :span="20"><el-input v-model="data.unit" placeholder="单位"><template slot="prepend">单位</template></el-input></el-col>
      <el-button @click="click_fun_edit('unit', data.unit, edit_log)">修改</el-button>
    </el-row>
    <el-row>
      <el-col :span="20"><el-input v-model="data.model" placeholder="型号"><template slot="prepend">型号</template></el-input></el-col>
      <el-button @click="click_fun_edit('model', data.model, edit_log)">修改</el-button>
    </el-row>

    <el-button @click="click_fun_edit('del', 1, edit_log)">删除</el-button>
    <el-button @click="click_fun_edit('del', 0, edit_log)">恢复</el-button>

    <br><br><br><br><br><br>

    <table v-if="log.data.length > 0" class="el-table" style="width:100%">
      <tr>
        <th style="text-align:center; width:150px">时间</th>
        <th style="text-align:center; width:auto">用户</th>
        <th style="text-align:center; width:150px">编号</th>
        <th style="text-align:left; width:auto">内容</th>
      </tr>
      <template v-for="(data, row) in log.data" :v-bind:key="row">
        <tr :key="row">
          <td style="text-align:center;">{{ data.time }}</td>
          <td style="text-align:center;">{{ data.uid }} / {{ data.name }}</td>
          <td style="text-align:center;">{{ data.sn }}</td>
          <td style="text-align:left;">{{ data.log }}</td>
        </tr>
      </template>
    </table>

    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="提示"
      width="50%"
      :show-close="false"
      :before-close="handleClose"
      :visible.sync="BomQuote.digShow > 0">
      <template v-for="(v1,k1) in BomQuote.list" :v-bind:key="k1">
        <template v-for="(v2,k2) in v1.quote" :v-bind:key="k2">
            <p :key="k2">{{ v2.sn }} ({{ v2.model }})</p>
        </template>
      </template>
      <template v-if="BomQuote.digShow == 1">
        <h3>该料已被如上所示BOM所引用, 所以禁止删除</h3>
      </template>
      <template v-if="BomQuote.digShow == 2">
        <h3>该料如果被编辑将影响如上所述BOM的描述信息, 确定要修改吗?</h3>
        <el-button @click="click_fun_edit('model2', data.model, edit_log)">确认修改</el-button>
      </template>
      <el-button @click="BomQuote.digShow = 0">取消</el-button>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      data: [],
      edit_log: '',
      sn: this.$route.query.sn,
      BomQuote: { digShow: 0, list: {} }
    }
  },
  created: function () {
    this.get_data()
    this.fun_get_log()
  },
  methods: {
    get_data () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_material',
        data: { sn: this.sn }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.data = res.data.data.material[0]
          this.BomQuote.list = res.data.data.bom
        }
      })
    },
    fun_get_log () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_log',
        data: { order: 'desc', screen: '%%', sn: this.sn, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
        } else {
          this.log = res.data.data
        }
      })
    },
    click_fun_edit (type, value, log) {
      if (type === 'model' && this.BomQuote.list.length > 0) { this.BomQuote.digShow = 2; return }
      if (type === 'model2') { type = 'model'; this.BomQuote.digShow = 0 }
      if (type === 'del' && value === 1 && this.BomQuote.list.length > 0) { this.BomQuote.digShow = 1; return }

      this.$axios({
        method: 'post',
        url: '/material/index/set_edit',
        data: { type: type, value: value, log: log, sn: this.sn }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.fun_get_log()
          this.$message.success(res.data.msg)
        }
      })
    },
    handleSizeChange (val) {
      this.log.per_page = val
      this.fun_get_log()
    },
    handleCurrentChange (val) {
      this.log.current_page = val
      this.fun_get_log()
    }
  }
}
</script>

<style lang="less" scoped>

</style>
