<template>
  <div>
    <div :v-if="user_data" style="margin-left: 0px; padding-left: 0px;">
      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">UID:</label></el-col>
        <el-col :span="10"><label class="el-form-item__label">{{ user_data.uid }}</label></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">创建时间:</label></el-col>
        <el-col :span="10"><label class="el-form-item__label">{{ user_data.time }}</label></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">用户名:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.name" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('name', user_data.name)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">密码:</label></el-col>
        <el-col :span="10"><el-input v-model="NewPswd" type="password" show-password /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('pswd', NewPswd)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">职位:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.position" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('position', user_data.position)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">钉钉:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.ding_talk_userIds" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('ding_talk_userIds', user_data.ding_talk_userIds)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">性别:</label></el-col>
        <el-col :span="10">
          <el-select v-model="user_data.sex" placeholder="请选择" style="width:100%">
            <el-option label="男" value="男">男</el-option>
            <el-option label="女" value="女">女</el-option>
          </el-select>
        </el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('sex', user_data.sex)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">姓名:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.name_cn" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('name_cn', user_data.name_cn)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">微信:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.name_wx" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('name_wx', user_data.name_wx)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">电话:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.tel" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('tel', user_data.tel)">修改</el-button></el-col>
      </el-row>

      <el-row :gutter="2">
        <el-col style="width: 100px;"><label class="el-form-item__label">邮箱:</label></el-col>
        <el-col :span="10"><el-input v-model="user_data.email" /></el-col>
        <el-col :span="5"><el-button @click="click_fun_edit('email', user_data.email)">修改</el-button></el-col>
      </el-row>
    </div>

    <div :v-if="log.data">
      <el-table :data="log.data">
        <el-table-column prop="time" label="时间" width="170px" />
        <el-table-column prop="name" label="用户" width="100px" />
        <el-table-column prop="uid" label="uid" width="100px" />
        <el-table-column prop="log" label="内容" width="auto" />
      </el-table>
      <div :v-if="log.data">
        <el-pagination
          hide-on-single-page
          :page-size="log.per_page"
          :page-count="log.last_page"
          :current-page="log.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 5, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      NewPswd: '',
      user_data: [],
      uid: window.sessionStorage.getItem('user_uid')
    }
  },
  created: function () {
    this.get_user_log()
    this.get_user_list()
  },
  methods: {
    // 获取
    get_user_list () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_info',
        data: { uid: this.uid }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.user_data = res.data.data[0]
        }
      })
    },
    get_user_log () {
      this.$axios({
        method: 'post',
        url: '/user/index/get_user_log',
        data: { uid: this.uid, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          // this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    handleSizeChange (val) {
      this.get_user_log(this.log.per_page = val)
    },
    handleCurrentChange (val) {
      this.get_user_log(this.log.current_page = val)
    },
    click_fun_edit (type, value) { // 编辑用户信息
      this.$axios({
        method: 'post',
        url: '/user/index/user_info_edit',
        data: { uid: this.uid, type: type, value: value }
      }).then(res => {
        if (parseInt(res.data.status) === 0) {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
        this.get_user_log()
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
