<template>

  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="(data, row) in path">
        <el-breadcrumb-item :key="row+'a'"><el-link @click="get_list('mode1', row)">{{ data.sn }}({{ data.model }})</el-link></el-breadcrumb-item>
      </template>
    </el-breadcrumb>
    <br>
    <div v-if="bom.length > 0">
      <table class="el-table" style="width:auto">
        <tr>
          <th style="width:30px">序号</th>
          <th style="width:130px">编号</th>
          <th style="width:auto">型号</th>
          <th style="width:50px">用量</th>
          <th style="width:auto">位号</th>
          <th style="width:50px">库存</th>
          <th style="width:50px; text-align: center;"><el-checkbox @change="fun_select('checked1', $event)"></el-checkbox></th>
        </tr>
        <template v-for="(data, row) in bom" :v-bind:key="row">
          <tr :key="row">
            <td v-if="row == 0 || bom[row].sn1 != bom[row-1].sn1" :rowspan="data.ReplaceSum">{{ data.Number }}</td>
            <td v-if="bom[row].sn2 != ''" style="color:rgb(201, 178, 178)">{{ data.sn2 }}</td>
            <td v-else><el-link @click="get_list('mode2', row)">{{ data.sn1 }}</el-link></td>
            <td v-if="bom[row].sn2 != ''" style="color:rgb(201, 178, 178)">{{ data.model }}</td>
            <td v-else>{{ data.model }}</td>
            <td style="text-align:center;">{{ data.amounts }}</td>
            <td style="text-align:left;">{{ data.position }}</td>
            <td style="text-align:center;">{{ data.sum }}</td>
            <td style="text-align:center;"><el-checkbox v-model="data.checked1"></el-checkbox></td>
          </tr>
        </template>
      </table>

      <div style="width: 300px;">
        <el-input placeholder="请输入新工单生产数量" v-model="AddNum" class="input-with-select">
          <el-button slot="append" icon="el-icon-plus" @click="fun_add_list(AddNum)"></el-button>
        </el-input>
      </div>

      <table class="el-table" style="width:auto">
        <tr>
          <th style="text-align:left;width:30px">序号</th>
          <th style="text-align:left;width:130px">编号</th>
          <th style="text-align:center;width:auto">型号</th>
          <th style="text-align:center;width:50px">用量</th>
          <th style="text-align:left;width:100px">位号</th>
          <th style="text-align:center;width:50px">库存</th>
          <th style="text-align:center;width:50px">需量</th>
          <th style="text-align:center;width:50px">待采购</th>
          <th style="text-align:center;width:50px">采购中</th>
          <th style="width:50px; text-align: center;"><el-checkbox @change="fun_select('checked2', $event)"></el-checkbox></th>
        </tr>
        <template v-for="(data, row) in bom" :v-bind:key="row">
          <tr v-if="data.LackSum > 0 && data.checked1" :key="row+'a'">
            <td >{{ data.Number }}</td>
            <td v-if="bom[row].sn2 != ''" style="color:rgb(201, 178, 178)">{{ data.sn2 }}</td>
            <td v-else><el-link @click="get_bom('mode2', row)">{{ data.sn1 }}</el-link></td>
            <td v-if="bom[row].sn2 != ''" style="color:rgb(201, 178, 178)">{{ data.model }}</td>
            <td v-else>{{ data.model }}</td>
            <td style="text-align:center;">{{ data.amounts }}</td>
            <td style="text-align:left;">{{ data.position }}</td>
            <td style="text-align:center;">{{ data.sum }}</td>
            <td style="text-align:center;">{{ data.DemandNum }}</td>
            <td style="text-align:center;">{{ data.LackSum }}</td>
            <td v-if="data.CaiGouNum >= data.LackSum" style="text-align:center;">{{ data.CaiGouNum }}</td>
            <td v-else style="text-align:center; color: rgb(255, 0, 0)">{{ data.CaiGouNum }}</td>
            <td style="text-align:center;"><el-checkbox v-model="data.checked2"></el-checkbox></td>
          </tr>
        </template>
          <tr>
            <td colspan="8"> <el-button style="width:100%" @click="fun_AddCaiGou">创建采购流程</el-button> </td>
          </tr>
      </table>
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      bom: [],
      AddNum: '',
      path: [{ sn: '', model: '根目录' }]
    }
  },
  created: function () {
    const temp = JSON.parse(localStorage.getItem('produceList_path'))
    if (temp != null) { this.path = temp }
    this.get_list('mode1', this.path.length - 1)
  },
  methods: {
    bom_data_init (data) {
      let cnt = 1
      const dt = data
      for (let a = 0; a < dt.length; a++) {
        let ReplaceSum = 0
        if (a > 0 && dt[a].sn1 !== dt[a - 1].sn1) { cnt++ }
        for (let b = 0; b < dt.length; b++) {
          if (dt[a].sn1 === dt[b].sn1) { ReplaceSum++ }
        }
        dt[a].Number = cnt
        dt[a].LackSum = 0
        dt[a].CaiGouNum = 0
        dt[a].DemandNum = 0
        dt[a].checked2 = false
        dt[a].ReplaceSum = ReplaceSum
        if (dt[a].sn2 === '') { dt[a].checked1 = true } else { dt[a].checked1 = false }
      }
      this.bom = dt
    },
    get_list (mode, row) {
      let sn0 = ''
      if (mode === 'mode1') {
        sn0 = this.path[row].sn
        this.path.splice(++row, this.path.length - row)
      } else if (mode === 'mode2') {
        sn0 = this.bom[row].sn; this.path.push({ sn: sn0, model: this.bom[row].model })
      }
      this.$axios({
        method: 'post',
        data: { sn0: sn0 },
        url: '/produce/index/list'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bom_data_init(res.data.data)
        }
      })
      localStorage.setItem('produceList_path', JSON.stringify(this.path))
    },
    fun_add_list (num) {
      const cg = []
      const bom = this.bom
      if (isNaN(parseInt(num)) || num <= 0) { return this.$message.error('数量:' + num + ' 填错啦') }
      for (let a = 0; a < bom.length; a++) {
        bom[a].LackSum = 0
        const sum = bom[a].amounts * num; bom[a].DemandNum = sum // 需量
        if (sum > bom[a].sum) {
          bom[a].LackSum = sum - bom[a].sum // 欠量
          if (bom[a].checked1) {
            if (bom[a].sn2.length > 0) { cg.push(bom[a].sn2) } else { cg.push(bom[a].sn1) }
          }
        }
      }
      if (cg.length > 0) {
        this.$axios({
          method: 'post',
          data: { sn_list: cg },
          url: '/produce/index/get_cg'
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            const data = res.data.data
            for (let b = 0; b < bom.length; b++) {
              bom[b].CaiGouNum = 0
              for (let c = 0; c < data.length; c++) {
                if (bom[b].sn2 === data[c].sn || bom[b].sn1 === data[c].sn) {
                  bom[b].CaiGouNum = data[c].num - data[c].in_num
                }
              }
            }
          }
        })
        this.$message.error('库存不足，禁止创建工单')
      } else {
        // 创建订单
        this.AddNum = 0
        const list = []
        for (let a = 0; a < bom.length; a++) {
          if (bom[a].checked1) {
            const temp = {
              loss: 0,
              unit: bom[a].unit,
              model: bom[a].model,
              amounts: bom[a].amounts,
              position: bom[a].position,
              describe: bom[a].describe,
              DemandNum: bom[a].DemandNum
            }
            if (bom[a].sn2.length > 0) { temp.sn = bom[a].sn2 } else { temp.sn = bom[a].sn1 }
            list.push(temp)
          }
        }
        const produce = { sum: num, state: '0', describe: '', path: this.path }
        if (list.length > 0) {
          this.$confirm('此操作将创建工单且工单中物料将自动出库, 是否继续?', '警告', {
            type: 'warning',
            cancelButtonText: '取消',
            confirmButtonText: '确定'
          }).then(() => {
            this.$axios({
              method: 'post',
              data: { sn_list: list, produce: produce },
              url: '/produce/index/add'
            }).then(res => {
              if (parseInt(res.data.status) !== 0) {
                this.$message.error(res.data.msg)
              } else {
                this.$router.push({ path: '/produce/list' })
              }
            })
          }).catch(() => { this.$message({ type: 'info', message: '已取消删除' }) })
        }
      }
    },
    fun_select (select, val) {
      const bom = this.bom
      for (let a = 0; a < bom.length; a++) {
        if (bom[a].sn2 === '') {
          bom[a][select] = val
        } else { bom[a][select] = false }
      }
    },
    fun_AddCaiGou () {
      const list = []
      const bom = this.bom
      for (let a = 0; a < bom.length; a++) {
        if (bom[a].checked1 && bom[a].checked2) {
          list.push({ sn: bom[a].sn1, num: bom[a].LackSum, model: bom[a].model, unit: bom[a].unit })
        }
      }
      this.$router.push({ path: '/purchase/add', query: { list: list } })
    }
  }
}
</script>

<style lang="less" scoped>
  td{
      padding:0px 0px 0px 0px
  }
</style>
