<template>
  <div>
    <div style="width: 100%; height: 30px;">
      <el-link type="primary" style="margin-right: 20px;" @click="click_fun_add">创建订单</el-link>
    </div>
    <br>
    <el-row>
      <el-col :span="15"><el-input v-model="screen" placeholder="查找" /></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px;" @click="get_list">查找</el-button></el-col>
    </el-row>

    <table v-if="list.data.length > 0" class="el-table" style="width:auto">
      <tr>
        <th style="text-align:center; width:120px">单号</th>
        <th style="text-align:center; width:auto">公司</th>
        <th style="text-align:center; width:120px">料号</th>
        <th style="text-align:center; width:auto;">型号</th>
        <th style="text-align:center; width: 60px">数量</th>
      </tr>
      <template v-for="(data, row) in list.data" :v-bind:key="row">
        <tr :key="row">
          <td v-if="row == 0 || list.data[row].number != list.data[row-1].number" :rowspan="data.row" style="padding-right: 20px;">
            <el-link @click="click_fun_to_edit(row)">CG{{ data.number }}</el-link>
          </td>
          <td v-if="row == 0 || list.data[row].number != list.data[row-1].number" :rowspan="data.row" style="padding-right: 20px;">{{ data.company1 }}</td>
          <td style="text-align:center;padding-right: 20px;">{{ data.sn }}</td>
          <td style="text-align:center;padding-right: 20px;">{{ data.model }}</td>
          <td style="text-align:center;padding-right: 20px;">{{ data.num }}</td>

        </tr>
      </template>
    </table>

    <div :v-if="list.data">
      <el-pagination
        hide-on-single-page
        :page-size="list.per_page"
        :page-count="list.last_page"
        :current-page="list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChangeList"
        @current-change="handleCurrentChangeList"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      screen: '',
      list: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      }
    }
  },
  created: function () {
    this.get_list()
  },
  methods: {
    get_list () {
      this.$axios({
        method: 'post',
        url: '/material/purchase/get_list',
        data: {
          screen: this.screen,
          per_page: this.list.per_page,
          current_page: this.list.current_page
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          const dt = res.data.data
          for (let a = 0; a < dt.data.length; a++) {
            dt.data[a].row = 0
            for (let b = 0; b < dt.data.length; b++) {
              if (dt.data[a].number === dt.data[b].number) { dt.data[a].row++ }
            }
          }
          this.list = dt
        }
      })
    },
    click_fun_add () {
      this.$router.push({ path: '/purchase/add' })
    },
    click_fun_to_edit (row) {
      this.$router.push({ path: '/purchase/details', query: { number: this.list.data[row].number } })
    },
    handleSizeChangeList (val) {
      this.list.per_page = val
      this.get_list()
    },
    handleCurrentChangeList (val) {
      this.list.current_page = val
      this.get_list()
    }
  }
}
</script>

<style lang="less" scoped>
td {
      padding:0px 0px 0px 0px
};
.header2{
  width: 100%;
  height:25px;
  padding: 10px 0px 10px 0px;
  background-color: rgb(232, 227, 236);
}
</style>
