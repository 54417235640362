<template>

  <div :v-if="list">
    <el-page-header :content="name" @back="$router.go(-1);" />
    <br>
    <el-date-picker v-model="select_data.time" style="width:150px" type="month" placeholder="选择月" @change="click_button1" />
    &emsp;
    <el-button @click="click_button2">最新数据获取</el-button>

    <div :v-if="TimePeriod">
      <div v-for="(item, index) in TimePeriod" :key="index">
        <template>
          <el-row>
            <el-col :span="24">
              <el-time-select v-model="item.time_start" style="width:120px" placeholder="起始时间" size="medium" :picker-options="{ start: '00:00', step: '00:10', end: '24:00' }" />
              <el-time-select v-model="item.time_end" style="width:120px" placeholder="结束时间" size="medium" :picker-options="{ start: '00:00', step: '00:10', end: '24:00' }" />
              &emsp; <el-button type="primary" size="mini" icon="el-icon-plus" :circle="true" @click="click_fun_set_TimePeriod(index, 1)" />
              &emsp; <el-button type="primary" size="mini" icon="el-icon-minus" :circle="true" @click="click_fun_set_TimePeriod(index, 0)" />
            </el-col>
          </el-row>
        </template>
      </div>
      <el-button style="margin-left: 0px; width: 240px;" @click="click_button_res_calc">重新计算</el-button>
    </div>

    <div v-if="wages.length > 0">
      <table id="out-table" class="el-table">
        <tr>
          <th rowspan="2" width="90px">姓名</th>
          <th rowspan="2" width="90px">日期</th>
          <th rowspan="2" width="60px">星期</th>
          <th rowspan="2" width="60px">原始数据</th>
          <template v-for="(data0, row0) in TimePeriod" :v-bind:key="row0">
            <th :key="row0+'a0'" style="text-align: center;">{{ data0.time_start }}</th>
            <th :key="row0+'a1'" style="text-align: center;">{{ data0.time_end }}</th>
          </template>
          <th rowspan="2">余数</th>
          <th rowspan="2">平时</th>
          <th rowspan="2">加班</th>
          <th rowspan="2">周末</th>
        </tr>
        <tr>
          <template v-for="(data1, row1) in TimePeriod" :v-bind:key="row1">
            <th :key="row1+'b'" style="text-align: center;" colspan="1" rowspan="1">上</th>
            <th :key="row1+'c'" style="text-align: center;" colspan="1" rowspan="1">下</th>
          </template>
        </tr>

        <!-- 表内容 -->
        <template v-for="(data2, row2) in wages" :v-bind:key="row2">
          <tr :key="row2">
            <td>{{ name }}</td>
            <td>{{ data2.day }}</td>
            <td>{{ data2.week }}</td>
            <td :key="row2+'b'">
              <template v-for="(data3, row3) in data2.CheckTime" :v-bind:key="row3">
                {{ data3 }}
              </template>
            </td>
            <template v-for="(data3, row4) in data2.TimePeriod" :v-bind:key="row4">
              <td v-if="data3.flag0" :key="row4+'f'" style="text-align: center;">{{ data3.CheckTime0.t2 }}</td> <td v-else :key="row4+'f'" />
              <td v-if="data3.flag1" :key="row4+'h'" style="text-align: center;">{{ data3.CheckTime1.t2 }}</td> <td v-else :key="row4+'h'" />
            </template>
            <td>{{ data2.TimePeriod.v0 }}</td>
            <td>{{ data2.TimePeriod.v1 }}</td>
            <td>{{ data2.TimePeriod.v2 }}</td>
            <td>{{ data2.TimePeriod.v3 }}</td>
          </tr>
        </template>
      </table>
      <button @click="exportExcel()">导出</button>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data () {
    return {
      list: [],
      wages: [],
      all_data: [],
      name: this.$route.query.name,
      select_data: { time: new Date() - (3600 * 1000 * 24 * 30) },
      TimePeriod: [
        { time_start: '08:30:00', time_end: '12:00:00' },
        { time_start: '13:00:00', time_end: '18:00:00' },
        { time_start: '18:40:00', time_end: '21:10:00' }
      ],
      week: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
    }
  },
  created: function () {
    this.get_list('/dingtalk/index/get_user_attendance')
  },
  methods: {
    exportExcel () {
      /* out-table关联导出的dom节点  */
      var xlsxParam = { raw: true }// 转换成excel时，使用原始的格式, 无该参数导出时数字可能会异常
      var wb = XLSX.utils.table_to_book(document.querySelector('#out-table'), xlsxParam)
      /* get binary string as output */
      var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.name + '_' + this.wages[0].day + '_' + this.$route.query.userid + '.xlsx')
      } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
      return wbout
    },
    get_list (url) {
      this.$axios({
        method: 'post',
        url: url,
        data: {
          userid: this.$route.query.userid,
          year: new Date(this.select_data.time).getFullYear(),
          month: new Date(this.select_data.time).getMonth() + 1
        }
      }).then(res => {
        if (res.data.status === 0) {
          this.all_data = res.data
          this.calculation()
          this.TimePeriod = JSON.parse(this.all_data.user_set[0].data).CheckTimePeriod
        } else { this.$message.error(this.all_data.msg) }
      }).catch(error => {
        this.$message.error(error.message)
      })
    },
    calculation () { // 计算结果
      const week = this.all_data.week
      const wages = this.all_data.data.wages !== undefined ? this.all_data.data.wages : []

      for (let a = 0; a < 31; a++) {
        if (wages.length <= a) {
          wages.push([])
          wages[a].CheckTime = []
          wages[a].week = this.week[(a + Number(week)) % 7]
          const t = this.FormDate(this.select_data.time)
          wages[a].day = a < 9 ? t.year + '-' + t.month + '-0' + (a + 1) : t.year + '-' + t.month + '-' + (a + 1)
        }

        if (!wages[a].TimePeriod) { wages[a].TimePeriod = [] }
        for (let b = 0; b < this.TimePeriod.length; b++) {
          if (wages[a].TimePeriod.length <= b) {
            wages[a].TimePeriod.push(JSON.parse(JSON.stringify(this.TimePeriod[b])))
            wages[a].TimePeriod[b].flag0 = false
            wages[a].TimePeriod[b].flag1 = false
            wages[a].TimePeriod[b].CheckTime0 = this.FormDate(new Date(wages[a].day).getTime())
            wages[a].TimePeriod[b].CheckTime1 = this.FormDate(new Date(wages[a].day).getTime())
          }
        }
      }

      this.list = []
      const Period = this.TimePeriod
      for (var i in this.all_data.data.dingtalk) { // 取原始数据
        this.list.push([])
        const json = JSON.parse(this.all_data.data.dingtalk[i].data)// eslint-disable-line no-unused-vars
        const CheckTime = this.FormDate(json.userCheckTime)// 获得时间
        for (var key in json) { this.list[i][key] = json[key] }
        this.list[i].MyUserCheckTime = CheckTime.t3

        const sCheck = new Date(CheckTime.t3).getTime() / 1000// 时间换算成时间戳
        var tt = []; for (let b = 0; b < Period.length; b++) {
          tt.push(new Date(CheckTime.t1 + ' ' + Period[b].time_start).getTime() / 1000)
          tt.push(new Date(CheckTime.t1 + ' ' + Period[b].time_end).getTime() / 1000)
        }
        for (let c = 0; c < tt.length; c++) {
          var NowDay0 = null
          var NowDay1 = null
          if (c === 0) {
            if (sCheck <= tt[c]) {
              NowDay0 = CheckTime
            }
          } else if (c >= tt.length - 1) {
            if (sCheck >= tt[c]) {
              NowDay1 = CheckTime
            }
          } else {
            var offsetTime = (tt[c + 1] - tt[c + 0]) / 2
            if (c % 2 === 1 && sCheck >= tt[c] && sCheck <= tt[c] + offsetTime) {
              NowDay1 = CheckTime
            } else if (c % 2 === 0 && sCheck >= tt[c] - offsetTime && sCheck <= tt[c]) {
              NowDay0 = CheckTime
            }
          }
          if (NowDay0 !== null || NowDay1 !== null) {
            for (let d = 0; d < wages.length; d++) {
              if (NowDay0 !== null && NowDay0.t1 === wages[d].day) {
                wages[d].TimePeriod[parseInt(c / 2)].flag0 = true
                wages[d].TimePeriod[parseInt(c / 2)].CheckTime0 = NowDay0
                break
              } else if (NowDay1 !== null && NowDay1.t1 === wages[d].day) {
                wages[d].TimePeriod[parseInt(c / 2)].flag1 = true
                wages[d].TimePeriod[parseInt(c / 2)].CheckTime1 = NowDay1
                break
              }
            }
            break
          }
        }
        for (let e = 0; e < wages.length; e++) {
          if (CheckTime.t1 === wages[e].day) {
            wages[e].CheckTime.push(CheckTime.t2)
          }
        }
      }
      for (let f = 0; f < wages.length; f++) {
        if (wages[0].TimePeriod.length === 1) {
          if (wages[f].TimePeriod[0].flag0 && wages[f].TimePeriod[0].flag1) {
            const WorkTime = (new Date(wages[f].day + ' ' + wages[f].TimePeriod[0].CheckTime1.t2) - new Date(wages[f].day + ' ' + this.TimePeriod[0].time_start)) / 1000 / 60
            if (wages[f].week === '星期六' || wages[f].week === '星期日') {
              wages[f].TimePeriod.v3 = WorkTime
            } else {
              wages[f].TimePeriod.v1 = WorkTime
              if (wages[f].TimePeriod.v1 > 480) {
                wages[f].TimePeriod.v1 = 480
                wages[f].TimePeriod.v2 = WorkTime - 8 * 60
              }
            }
          }
        } else if (wages[0].TimePeriod.length === 2) {
          var WorkTime = 0; var EveningTime = 0
          if (wages[f].TimePeriod[0].flag0 && wages[f].TimePeriod[0].flag1) { // 白天
            WorkTime = (new Date(wages[f].day + ' ' + this.TimePeriod[0].time_end) - new Date(wages[f].day + ' ' + this.TimePeriod[0].time_start)) / 1000 / 60
          }
          if (wages[f].TimePeriod[1].flag0 && wages[f].TimePeriod[1].flag1) { // 晚上
            EveningTime = (new Date(wages[f].day + ' ' + wages[f].TimePeriod[1].CheckTime1.t2) - new Date(wages[f].day + ' ' + this.TimePeriod[1].time_start)) / 1000 / 60
          }
          if (wages[f].week === '星期六' || wages[f].week === '星期日') {
            if (WorkTime + EveningTime > 0) { wages[f].TimePeriod.v3 = WorkTime + EveningTime }
          } else {
            if (WorkTime > 0) {
              wages[f].TimePeriod.v0 = WorkTime - 8 * 60
              wages[f].TimePeriod.v1 = WorkTime; if (wages[f].TimePeriod.v1 > 480) { wages[f].TimePeriod.v1 = 480 }
            }
            if (EveningTime > 0) { wages[f].TimePeriod.v2 = EveningTime }
          }
        } else if (wages[0].TimePeriod.length === 3) {
          var Morning = 0; var Afternoon = 0; var Evening = 0
          if (wages[f].TimePeriod[0].flag0 && wages[f].TimePeriod[0].flag1) { // 上
            Morning = (new Date(wages[f].day + ' ' + this.TimePeriod[0].time_end) - new Date(wages[f].day + ' ' + this.TimePeriod[0].time_start)) / 1000 / 60
          }
          if (wages[f].TimePeriod[1].flag0 && wages[f].TimePeriod[1].flag1) { // 下
            Afternoon = (new Date(wages[f].day + ' ' + this.TimePeriod[1].time_end) - new Date(wages[f].day + ' ' + this.TimePeriod[1].time_start)) / 1000 / 60
          }
          if (wages[f].TimePeriod[2].flag0 && wages[f].TimePeriod[2].flag1) { // 晚
            Evening = parseInt((new Date(wages[f].day + ' ' + wages[f].TimePeriod[2].CheckTime1.t2) - new Date(wages[f].day + ' ' + this.TimePeriod[2].time_start)) / 1000 / 60 / 30) * 30 // 除30取整再乘回30得到半小时精度
          }

          if (wages[f].week === '星期六' || wages[f].week === '星期日') {
            if (Morning + Afternoon + Evening > 0) { wages[f].TimePeriod.v3 = Morning + Afternoon + Evening }
          } else {
            if (Morning > 0 && Afternoon > 0) {
              wages[f].TimePeriod.v0 = (Morning + Afternoon) - 8 * 60
              wages[f].TimePeriod.v1 = Morning + Afternoon; if (wages[f].TimePeriod.v1 > 480) { wages[f].TimePeriod.v1 = 480 }
            }
            if (Evening > 0) { wages[f].TimePeriod.v2 = Evening }
          }
        }
      }
      this.wages = wages
    },
    click_button_res_calc () {
      this.calculation()
    },
    FormDate (fmt) { // 时间格式化
      const t = new Date(fmt)
      const year = t.getFullYear() + ''
      const day = t.getDate() < 10 ? '0' + t.getDate() : t.getDate() + ''
      const hours = t.getHours() < 10 ? '0' + t.getHours() : t.getHours() + ''
      const sec = t.getSeconds() < 10 ? '0' + t.getSeconds() : t.getSeconds() + ''
      const minutes = t.getMinutes() < 10 ? '0' + t.getMinutes() : t.getMinutes() + ''
      const month = Number(t.getMonth() + 1) < 10 ? '0' + Number(t.getMonth() + 1) : Number(t.getMonth() + 1) + ''
      const time2 = {
        year: year,
        month: month,
        day: day,
        hours: hours,
        minutes: minutes,
        sec: sec,
        t1: year + '-' + month + '-' + day,
        t2: hours + ':' + minutes + ':' + sec,
        t3: year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + sec
      }
      return time2
    },
    click_button1 () {
      this.get_list('/dingtalk/index/get_user_attendance')
    },
    click_button2 () {
      this.get_list('/dingtalk/index/get_ding_attendance')
    },
    click_fun_set_TimePeriod (index, set) {
      if (set > 0) {
        this.TimePeriod.splice(index + 1, 0, { dataItem: 'time' + (index + 1), time_start: '08:00', time_end: '08:30' })
      } else if (this.TimePeriod.length > 1) {
        this.TimePeriod.splice(index, 2)
      }
    },
    selectArInfo (val) {
      this.selectArr = val
    }
  }
}
</script>

<style lang="less" scoped>
</style>
