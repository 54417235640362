<template>
  <div>
    <el-page-header @back="$router.go(-1);" content="详情"> </el-page-header>
    <br>

    <div id="MyPrintf" style="width: 100%;">
      <table v-if="list.data.length > 0" class="el-table" style="width:auto; width: 95%;">
        <tr><th colspan="9" style="text-align: center; font-size: 24px;">{{list.data[0].company0}}</th></tr>
        <tr><th colspan="9" style="text-align: center; font-size: 20px;">采购订单</th></tr>
        <tr><td colspan="9" style="text-align: left;"><el-link @click="click_fun_to_edit()">单号: CG{{list.data[0].number}}</el-link></td></tr>

        <tr>
          <td colspan="9">
            <table style="width: 100%;">
              <tr>
                <td style="width: 60px;"></td>
                <td style="text-align: center; font-size: 15px;">需方</td>
                <td style="text-align: center; font-size: 15px;">供方</td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 12px;">公司</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].company0}}</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].company1}}</td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 12px;">联系人</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].name0}}</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].name1}}</td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 12px;">电话</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].tel0}}</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].tel1}}</td>
              </tr>
              <tr>
                <td style="text-align: left; font-size: 12px;">地址</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].addr0}}</td>
                <td style="text-align: left; font-size: 12px; padding-left: 10px;">{{list.data[0].addr1}}</td>
              </tr>
            </table>
          </td>
        </tr>

        <tr><td colspan="9" style="text-align: left; font-size: 15px;"><pre>{{list.data[0].contract}}</pre></td></tr>

        <tr>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">序号</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">料号</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: auto;">型号</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">单位</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">数量</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">单价</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 50px;">金额</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: 160px;">交货日期</th>
          <th style="text-align: center; padding: 0px 10px 0px 10px; width: auto;">备注</th>
        </tr>
        <template v-for="(data, row) in list.data" :v-bind:key="row">
          <tr :key="row">
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{row+1}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].sn}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].model}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].unit}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].num}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].price}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].je.toFixed(2)}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].time}}</td>
            <td style="text-align: center; padding: 0px 10px 0px 10px;">{{list.data[row].remarks}}</td>
          </tr>
        </template>
        <tr><th colspan="9" style="text-align: right;">合计: {{list.zj}}元</th></tr>
      </table>
    </div>
    <el-button v-print="printObj">打印</el-button>
    <br><br><br><br>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: {
        data: {
          data: [],
          total: 1, // 总计
          per_page: 20, // 每页
          last_page: 1, // 最后一页
          current_page: 1 // 当前页面
        },
        screen: '',
        number: this.$route.query.number
      },
      printObj: { // 配置打印参数
        id: 'MyPrintf', // 要打印的容器的id
        popTitle: '标签打印', // 页眉标题
        extraCss: 'www.sdhmbim.com',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      }
    }
  },
  created: function () {
    this.get_list()
  },
  methods: {
    get_list () {
      this.$axios({
        method: 'post',
        url: '/material/purchase/get_list',
        data: {
          screen: '',
          per_page: 10000,
          current_page: 1,
          number: this.list.number
        }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          let zj = 0
          const dt = res.data.data
          for (let a = 0; a < dt.data.length; a++) {
            dt.data[a].je = dt.data[a].num * dt.data[a].price
            zj += dt.data[a].je
          }
          dt.zj = zj.toFixed(2); this.list = dt
        }
      })
    },
    click_fun_to_edit () {
      this.$router.push({ path: '/purchase/edit', query: { number: this.$route.query.number } })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
