<template>

  <div>
    <el-page-header content="出入库" @back="$router.go(-1);" />
    <br><br>

    <el-row>
      <el-col :span="2"><label class="el-form-item__label">数量</label></el-col>
      <el-col :span="20"><label class="el-form-item__label">{{ data.sum }}</label></el-col>
    </el-row>
    <el-row>
      <el-col :span="2"><label class="el-form-item__label">编号</label></el-col>
      <el-col :span="20"><label class="el-form-item__label">{{ data.sn }}</label></el-col>
    </el-row>
    <el-row>
      <el-col :span="2"><label class="el-form-item__label">型号</label></el-col>
      <el-col :span="20"><label class="el-form-item__label">{{ data.model }}</label></el-col>
    </el-row>

    <el-row>
      <el-col :span="10"><el-input v-model="edit_in" placeholder="入库数量"><template slot="prepend">入库</template></el-input></el-col>
      <el-col :span="1"><label class="el-form-item__label">{{ data.unit }}</label></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px;" @click="click_fun_inout('in')">入库</el-button></el-col>
    </el-row>
    <br>
    <el-row>
      <el-col :span="10"><el-input v-model="edit_out" placeholder="出库数量"><template slot="prepend">出库</template></el-input></el-col>
      <el-col :span="1"><label class="el-form-item__label">{{ data.unit }}</label></el-col>
      <el-col :span="3"><el-button style="margin-left: 20px;" @click="click_fun_inout('out')">出库</el-button></el-col>
    </el-row>

    <el-row>
      <el-col :span="20"><el-input v-model="edit_log" type="textarea" placeholder="出入库原因" style="margin-top:10px"><template slot="prepend">出入库原因</template></el-input></el-col>
    </el-row>

    <br><br><br>

    <table v-if="log.data.length > 0" class="el-table" style="width:100%">
      <tr>
        <th style="text-align:center; width:150px">时间</th>
        <th style="text-align:center; width:auto">用户</th>
        <!-- <th style="text-align:center; width:150px">编号</th> -->
        <th style="text-align:left; width:auto">内容</th>
      </tr>
      <template v-for="(data, row) in log.data" :v-bind:key="row">
        <tr :key="row">
          <td style="text-align:center;">{{ data.time }}</td>
          <td style="text-align:center;">{{ data.uid }} / {{ data.name }}</td>
          <!-- <td style="text-align:center;">{{ data.sn }}</td> -->
          <td style="text-align:left;">{{ data.log }}</td>
        </tr>
      </template>
    </table>

    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      data: [],
      edit_in: '',
      edit_out: '',
      edit_log: '',
      sn: this.$route.query.sn
    }
  },
  created: function () {
    this.get_data()
    this.fun_get_log()
  },
  methods: {
    get_data () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_material',
        data: { sn: this.sn }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.data = res.data.data.material[0]
        }
      })
    },
    fun_get_log () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_log',
        data: { order: 'desc', screen: '%%', sn: this.sn, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
        } else {
          this.log = res.data.data
        }
      })
    },
    click_fun_inout (type) {
      this.$confirm('确认修改?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
        let value = this.edit_in
        if (type === 'out') { value = 0 - parseInt(this.edit_out) }
        this.$axios({
          method: 'post',
          url: '/material/index/set_inout',
          data: { value: value, log: this.edit_log, sn: this.sn }
        }).then(res => {
          if (parseInt(res.data.status) !== 0) {
            this.$message.error(res.data.msg)
          } else {
            this.fun_get_log()
            this.$message.success(res.data.msg)
          }
        })
      })
    },
    handleSizeChange (val) {
      this.log.per_page = val
      this.fun_get_log()
    },
    handleCurrentChange (val) {
      this.log.current_page = val
      this.fun_get_log()
    }
  }
}
</script>

<style lang="less" scoped>

</style>
