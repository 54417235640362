<template>

  <div>
    <el-page-header content="文档" @back="$router.go(-1);" />

    <el-row>
      <el-col :span="2"><label class="el-form-item__label">编号</label></el-col>
      <el-col :span="20"><label class="el-form-item__label">{{ data.sn }}</label></el-col>
    </el-row>
    <el-row>
      <el-col :span="2"><label class="el-form-item__label">型号</label></el-col>
      <el-col :span="20"><label class="el-form-item__label">{{ data.model }}</label></el-col>
    </el-row>

    <div style="width:95%">
      <table class="el-table" style="width:100%" v-loading="loading" :element-loading-text="percent">
        <tr>
          <!-- <th style="text-align:center;">序号</th> -->
          <th style="text-align:left;">时间</th>
          <!-- <th style="text-align:center;">用户</th> -->
          <th style="text-align:left;">文件编号</th>
          <th style="text-align:left;">文件名称</th>
          <th style="text-align:left;">文件大小(byte)</th>
          <th style="text-align:left;">描述</th>
          <th style="text-align:center;"><el-checkbox v-model="del_checked">全部</el-checkbox></th>
        </tr>
        <template v-for="(val1, row1) in list.data.data" :v-bind:key="row1">
          <tr v-if="del_checked || val1.del == '0'" :key="row1+'1'">
            <td style="text-align:left;">{{ val1.time }}</td>
            <td style="text-align:left;">{{ val1.file_id }}</td>
            <td style="text-align:left;">{{ val1.file_name }}</td>
            <td style="text-align:left;">{{ val1.size }}</td>
            <td style="text-align:left;">{{ val1.file_bz }}</td>
            <td ><el-button type="danger" size="mini" icon="el-icon-edit" @click="click_edit(row1)" circle></el-button></td>
            <td v-if="val1.del == '0'"><el-button type="primary" size="mini" icon="el-icon-download" @click="fun_download(val1)" circle></el-button></td>
            <td v-if="val1.del != '0'"><el-button type="warning" size="mini" icon="el-icon-download" @click="fun_download(val1)" circle></el-button></td>
          </tr>
        </template>

        <template v-for="(val2, row2) in fileList" :v-bind:key="row2">
          <tr :key="row2+'2'">
            <td style="text-align:left;" colspan="1">待上传</td>
            <td style="text-align:left;" colspan="2"><el-input v-model="val2.msg" placeholder="描述信息"></el-input></td>
            <td style="text-align:left;" colspan="1">{{val2.name}}</td>
            <td style="text-align:left;" colspan="1">{{val2.size}}</td>
            <td style="text-align:left;" colspan="1"></td>
            <td ><el-button type="danger" size="mini" @click="click_delete(row2)" icon="el-icon-delete" circle></el-button></td>
            <td ><el-button type="danger" size="mini" @click="click_upload(row2)" icon="el-icon-upload2" circle></el-button></td>
          </tr>
        </template>
      </table>
      <input ref="ChangeFiles" type="file" style="display: none" @change="ChangeFiles" />
      <el-button type="danger" @click="SelectFileClick" style="width:100%">选择待上传文件</el-button>
    </div>

    <el-dialog v-if="dialog_edit.show"
        title="编辑"
        :visible.sync="dialog_edit.show"
        width="80%">
        <el-row>
          <el-col :span="2"><label class="el-form-item__label">名称</label></el-col>
          <el-col :span="18"><label class="el-form-item__label">{{ list.data.data[dialog_edit.select].file_name }}</label></el-col>
          <el-col :span="2">
            <el-button v-if="(list.data.data[dialog_edit.select].del === '0')" type="danger" @click="click_editFile(dialog_edit.select, 'del')" icon="el-icon-close"></el-button>
            <el-button v-else type="danger" @click="click_editFile(dialog_edit.select, 'del')" icon="el-icon-check"></el-button>
          </el-col>
        </el-row>
        <br>
        <el-row>
          <el-col :span="2"><label class="el-form-item__label">描述</label></el-col>
          <el-col :span="18"><el-input v-model="list.data.data[dialog_edit.select].file_bz" placeholder="描述信息"></el-input></el-col>
          <el-col :span="2"><el-button type="danger" @click="click_editFile(dialog_edit.select, 'bz')" icon="el-icon-edit"></el-button></el-col>
        </el-row>
    </el-dialog>

    <table v-if="log.data.length > 0" class="el-table" style="width:100%">
      <tr>
        <th style="text-align:center; width:150px">时间</th>
        <th style="text-align:center; width:auto">用户</th>
        <th style="text-align:left; width:auto">内容</th>
      </tr>
      <template v-for="(data, row) in log.data" :v-bind:key="row">
        <tr :key="row">
          <td style="text-align:center;">{{ data.time }}</td>
          <td style="text-align:center;">{{ data.uid }} / {{ data.user }}</td>
          <td style="text-align:left;">{{ data.log }}</td>
        </tr>
      </template>
    </table>

    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      list: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      data: [],
      fileList: [],
      percent: '0%',
      loading: false,
      del_checked: false,
      dialog_edit: { show: false, select: -1 },
      sn: this.$route.query.sn
    }
  },
  created: function () {
    this.get_log()
    this.get_data()
    this.get_Filelist()
  },
  methods: {
    get_data () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_material',
        data: { sn: this.sn }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.data = res.data.data.material[0]
        }
      })
    },
    get_log () {
      this.$axios({
        method: 'post',
        url: '/material/index/getFileLog',
        data: { sn: this.sn, per_page: this.log.per_page, current_page: this.log.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    get_Filelist () {
      this.$axios({
        method: 'post',
        url: '/material/index/getFileList',
        data: { sn: this.sn, per_page: this.list.per_page, current_page: this.list.current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list.data = res.data.data
        }
      })
    },
    down (v) {
      const that = this
      that.percent = '0'
      this.loading = true
      const xhr = new XMLHttpRequest()
      xhr.open('GET', this.$axios.defaults.baseURL + 'material/index/download?id=' + v.id, true)
      xhr.responseType = 'blob'
      xhr.onprogress = function (event) {
        that.percent = event.loaded.toString()
      }
      xhr.onload = function (aEvt) {
        if (this.status === 200) {
          that.loading = false
          var blob = this.response
          const fileName = v.sn + '_' + v.file_id + '_' + v.file_name
          const objectURL = URL.createObjectURL(new Blob([blob])) // chrome不受文件你大小限制导出文件
          var a = document.createElement('a')
          a.download = fileName
          a.href = objectURL
          a.click()
          a.remove()
        }
      }
      xhr.setRequestHeader('token', window.sessionStorage.getItem('token'))// 传递 token
      xhr.send()
    },
    fun_download (v) {
      if (v.del === '0') {
        this.down(v)
      } else {
        this.$confirm('该文件已作废，确定下载吗?', '警告', { confirmButtonText: '下载', cancelButtonText: '取消', type: 'warning' }).then(() => {
          this.down(v)
        })
      }
    },
    click_edit (row) {
      this.dialog_edit.show = true
      this.dialog_edit.select = row
    },
    click_editFile (row, type) {
      if (this.list.data.data[row].del !== '0') { this.list.data.data[row].del = '0' } else { this.list.data.data[row].del = '1' }
      this.$axios({
        method: 'post',
        url: '/material/index/file_edit',
        data: { data: this.list.data.data[row], type: type }
      }).then(res => {
        this.get_log()
        this.get_Filelist()
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    SelectFileClick () {
      this.$refs.ChangeFiles.click()
    },
    ChangeFiles (event) {
      const file = event.target.files[0]
      this.fileList.push({ sn: this.sn, name: file.name, size: file.size, file: file, msg: '', type: 'txt' })
    },
    click_delete (row) {
      this.fileList.splice(row, 1)
    },
    click_upload (row) {
      let flag = true
      for (let i = 0; i < this.list.data.data.length; i++) {
        if (flag === true && this.fileList[row].name === this.list.data.data[i].file_name && this.fileList[row].size === parseInt(this.list.data.data[i].size)) {
          flag = false
          this.$confirm('该文件已经被提交过, 是否再次提交?', '提示', { confirmButtonText: '提交', cancelButtonText: '取消', type: 'warning' }).then(() => {
            this.UpLoad(this.fileList[row])
          })
        }
      }
      if (flag) {
        this.$confirm('您确认提交该文档吗?', '提示', { confirmButtonText: '提交', cancelButtonText: '取消', type: 'warning' }).then(() => {
          this.UpLoad(this.fileList[row])
        })
      }
    },
    UpLoad (dat) {
      const formData = new FormData()
      formData.append('sn', dat.sn)
      formData.append('msg', dat.msg)
      formData.append('file', dat.file)
      formData.append('type', dat.type)
      this.$axios({
        data: formData,
        method: 'post',
        url: '/material/index/upload',
        'Content-type': 'multipart/form-data'
      }).then(res => {
        this.get_Filelist()
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleSizeChange (val) {
      this.log.per_page = val
      this.get_log()
    },
    handleCurrentChange (val) {
      this.log.current_page = val
      this.get_log()
    }
  }
}
</script>

<style lang="less" scoped>
  input[type="file"] {
    color: transparent;
  }
</style>
