<template>
  <div>
    <table v-if="list.data.length > 0" class="el-table" >
      <tr>
        <th >序号</th>
        <th >料号</th>
        <th >型号</th>
        <th >位置</th>
        <th >用量</th>
        <th >总量</th>
        <th >损耗</th>
        <th >单位</th>
        <th >描述</th>
      </tr>

      <template v-for="(data1, row1) in list.data" :v-bind:key="row1">
        <tr :key="row1+'a'">
          <td style="">{{ row1+1 }}</td>
          <td style="" @click="click_fun_edit(data1.sn)"><el-link>{{ data1.sn }}</el-link></td>
          <td style="">{{ data1.model }}</td>
          <td style="">{{ data1.position }}</td>
          <td style="">{{ data1.amounts }}</td>
          <td style="">{{ data1.sum }}</td>
          <td style="">{{ data1.loss }}</td>
          <td style="">{{ data1.unit }}</td>
          <td style="">{{ data1.describe }}</td>
        </tr>
      </template>
        <tr :key="'b'">
          <td colspan="9"><el-button style="width:100%" @click="dialog_add=true; material_get()">添加物料</el-button></td>
        </tr>
    </table>
    <div :v-if="list.data">
      <el-pagination
        hide-on-single-page
        :page-size="list.per_page"
        :page-count="list.last_page"
        :current-page="list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChangeList"
        @current-change="handleCurrentChangeList"
      />
    </div>
    <br>

    <el-row>
      <el-col :span="20"><el-input v-model="add_msg" type="textarea" :rows="2" placeholder="请输入内容"></el-input></el-col>
        <el-col :span="2"> <el-button @click="fun_add_log(add_msg)">添加</el-button> </el-col>
    </el-row>
    <el-row>
      <el-col :span="20"><el-input v-model="in_num" :rows="2" placeholder="请输入成品入库数"></el-input></el-col>
        <el-col :span="2"> <el-button @click="fun_in_num(in_num)">入库</el-button> </el-col>
    </el-row>
    <br>
    <table v-if="bom.length > 0" class="el-table" >
      <tr><th colspan="4" style="text-align: center;">领料表与BOM不符明细</th></tr>
      <tr>
        <th >料号</th>
        <th >型号</th>
        <th >位置</th>
        <th >用量</th>
      </tr>

      <template v-for="(data3, row3) in bom" :v-bind:key="row2">
        <tr :key="row3">
          <td>{{data3.sn1}}</td>
          <td>{{data3.model}}</td>
          <td>{{data3.position}}</td>
          <td>{{data3.amounts}}</td>
        </tr>
      </template>
    </table>

    <br><br>
    <table v-if="log.data.length > 0" class="el-table" >
      <tr>
        <th >序号</th>
        <th >uid</th>
        <th >用户</th>
        <th >料号</th>
        <th >时间</th>
        <th >内容</th>
      </tr>

      <template v-for="(data1, row2) in log.data" :v-bind:key="row2">
        <tr :key="row2">
          <td style="">{{ row2+1 }}</td>
          <td style="">{{ data1.uid }}</td>
          <td style="">{{ data1.user }}</td>
          <td style="">{{ data1.sn }}</td>
          <td style="">{{ data1.time }}</td>
          <td style="">{{ data1.log }}</td>
        </tr>
      </template>
    </table>

    <div :v-if="log.data">
      <el-pagination
        hide-on-single-page
        :page-size="log.per_page"
        :page-count="log.last_page"
        :current-page="log.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChangeLog"
        @current-change="handleCurrentChangeLog"
      />
    </div>

    <el-dialog v-if="dialog_add" title="添加新物料" :visible.sync="dialog_add" width="90%">
      <el-row>
        <el-col :span="15"><el-input v-model="material.screen" placeholder="查找" /></el-col>
        <el-col :span="3"><el-button style="margin-left: 20px;" @click="material_get">查找</el-button></el-col>
      </el-row>

      <table v-if="material.data.length > 0" class="el-table" style="width:auto">
        <tr>
          <th style="text-align:center; width:30px"></th>
          <th style="text-align:center; width:130px">料号</th>
          <th style="text-align:center; width:130px">类型</th>
          <th style="text-align:center; width:auto">型号</th>
          <th style="text-align:center; width:50px">存库</th>
          <th style="text-align:center; width:50px">单位</th>
          <th style="text-align:center; width:130px">位置</th>
          <th style="text-align:center; width:50px">用量</th>
          <th style="text-align:center; width:50px">总量</th>
          <th style="text-align:center; width:50px">损耗</th>
          <th style="text-align:center; width:130px">描述</th>
        </tr>
        <template v-for="(data, row) in material.data" :v-bind:key="row">
          <tr :key="row">
            <td style="text-align:center;">{{ row+1 }}</td>
            <td style="text-align:center;">{{ data.sn }}</td>
            <td style="text-align:center;">{{ data.type }}</td>
            <td style="text-align:left;">{{ data.model }}</td>
            <td style="text-align:center;">{{ data.sum }}</td>
            <td style="text-align:center;">{{ data.unit }}</td>
            <td style="text-align:left;"><el-input v-model="data.position"></el-input></td>
            <td style="text-align:center;"><el-input v-model="data.amounts"></el-input></td>
            <td style="text-align:center;"><el-input v-model="data.DemandNum"></el-input></td>
            <td style="text-align:center;"><el-input v-model="data.loss"></el-input></td>
            <td style="text-align:left;"><el-input v-model="data.describe"></el-input></td>
            <td style="text-align:center;"><el-button @click="click_add(row)">添加</el-button></td>
          </tr>
        </template>
      </table>
      <div :v-if="material.data">
        <el-pagination
          hide-on-single-page
          :page-size="material.per_page"
          :page-count="material.last_page"
          :current-page="material.current_page"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
          @size-change="handleSizeChangeMaterial"
          @current-change="handleCurrentChangeMaterial"
        />
      </div>
    </el-dialog>

    <br><br>
  </div>

</template>

<script>
export default {
  data () {
    return {
      log: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      list: {
        data: [],
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      material: {
        data: [],
        screen: '',
        total: 1, // 总计
        per_page: 20, // 每页
        last_page: 1, // 最后一页
        current_page: 1 // 当前页面
      },
      bom: [],
      in_num: 0,
      add_msg: '',
      dialog_add: false,
      sn: this.$route.query.sn,
      number: this.$route.query.number
    }
  },
  created: function () {
    this.log_get()
    this.list_get()
    this.bom_get(this.sn)
  },
  methods: {
    list_get (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/produce/index/get_works_list',
        data: { number: this.number, per_page: this.list.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    fun_in_num (num) {
      this.$axios({
        method: 'post',
        url: '/produce/index/receiving',
        data: { number: this.number, num: num }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log_get(this.log.current_page)
        }
      })
    },
    material_get () {
      this.$axios({
        method: 'post',
        url: '/material/index/get_list',
        data: { per_page: this.material.per_page, current_page: this.material.current_page, mode: 'normal', screen: this.material.screen }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          const dt = res.data.data
          for (let a = 0; a < dt.data.length; a++) {
            dt.data[a].loss = 0
            dt.data[a].amounts = 0
            dt.data[a].DemandNum = 0
            dt.data[a].position = ''
            dt.data[a].describe = ''
          }
          this.material = dt
        }
      })
    },
    bom_get (sn) {
      this.$axios({
        method: 'post',
        data: { sn0: sn },
        url: '/produce/index/list'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bom = []
          const all = res.data.data
          const now = this.list.data
          // 找出bom有但工单无的物料
          for (let a = 0; a < all.length; a++) {
            let flag = true
            for (let b = 0; b < now.length; b++) {
              if (all[a].sn === now[b].sn) {
                flag = false; break
              }
            }
            if (flag) { this.bom.push(all[a]) }
          }
          // 找出工单有但bom无的物料
          for (let c = 0; c < now.length; c++) {
            let flag = true
            for (let d = 0; d < all.length; d++) {
              if (now[d].sn === all[d].sn) {
                flag = false; break
              }
            }
            if (flag) { this.bom.push(now[c]) }
          }
        }
      })
    },
    log_get (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/produce/index/get_log',
        data: { number: this.number, per_page: this.log.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.log = res.data.data
        }
      })
    },
    fun_add_log (msg) {
      this.$axios({
        method: 'post',
        url: '/produce/index/add_log',
        data: { number: this.number, msg: msg }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.add_msg = ''
          this.log_get(this.log.current_page)
        }
      })
    },
    click_add (row) {
      this.$axios({
        method: 'post',
        data: { number: this.number, sn_list: [this.material.data[row]] },
        url: '/produce/index/add'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.dialog_add = false
        }
      })
    },
    click_fun_edit (sn) {
      this.$router.push({ path: '/produce/edit', query: { number: this.number, sn: sn } })
    },
    handleSizeChangeLog (val) {
      this.log.per_page = val
      this.log_get(this.log.current_page)
    },
    handleCurrentChangeLog (val) {
      this.log_get(val)
      this.log.current_page = val
    },
    handleSizeChangeList (val) {
      this.list.per_page = val
      this.list_get(this.list.current_page)
    },
    handleCurrentChangeList (val) {
      this.list_get(val)
      this.list.current_page = val
    },
    handleSizeChangeMaterial (val) {
      this.material.per_page = val
      this.material_get()
    },
    handleCurrentChangeMaterial (val) {
      this.material.current_page = val
      this.material_get()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
