<template>

  <router-view />

</template>

<script>
export default {
  data () {
    return {
    }
  },
  created: function () {

  },
  methods: {

  }
}
</script>

  <style lang="less" scoped>
  </style>
