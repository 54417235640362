<template>
  <div>
    <template v-if="account.list.length > 0">
      <el-row>
        <el-col :span="5">
          <el-select placeholder="请选择帐户" v-model="account.number">
            <el-option
              v-for="item in account.list"
              :key="item.number"
              :label="item.account"
              :value="item.number">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-date-picker v-model="bills.trading_time" type="datetime"></el-date-picker>
        </el-col>
        <el-col :span="5">
          <el-input style="width:100%" v-model="bills.amounts" placeholder="请输入交易金额"></el-input><br>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-input style="width:100%" v-model="bills.describe" type="textarea" :rows="2" placeholder="请输入交易原因"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-button style="width:100%" @click="bills_add">添加</el-button>
        </el-col>
      </el-row>
    </template>

    <table v-if="list.data.length > 0" class="el-table" >
      <tr>
        <th rowspan="2" colspan="1">序号</th>
        <th rowspan="2" colspan="1">单号</th>
        <th rowspan="2" colspan="1">交易时间</th>
        <th rowspan="2" colspan="1">用户</th>
        <template v-for="(data, row) in account.list" :v-bind:key="row">
          <th :key="row+'a'" rowspan="1" colspan="2" style="text-align:center;">{{data.account}}</th>
        </template>
        <th rowspan="2" colspan="1">总计</th>
        <th rowspan="2" colspan="1">内容</th>
      </tr>
      <tr>
        <template v-for="(data, row) in account.list" :v-bind:key="row">
          <th :key="row+'b'" rowspan="1" colspan="1">金额</th>
          <th :key="row+'c'" rowspan="1" colspan="1">余额</th>
        </template>
      </tr>

      <template v-for="(data1, row1) in list.data" :v-bind:key="row1">
        <tr :key="row1">
          <td style="padding:5px 20px 5px 20px">{{ row1+1 }}</td>
          <td style="padding:5px 20px 5px 20px" @click="click_fun_edit(data1.number)"><el-link>{{ data1.number }}</el-link></td>
          <td style="padding:5px 20px 5px 20px">{{ data1.trading_time }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.user }}({{data1.uid}})</td>
          <template v-for="(data2, row2) in account.list" :v-bind:key="row2">
            <template v-if="data1.account_id != data2.number">
              <td :key="row2+'d'" style="padding:5px 20px 5px 20px">--</td>
              <td :key="row2+'e'" style="padding:5px 20px 5px 20px">--</td>
            </template>
            <template v-else>
              <td :key="row2+'f'" style="padding:5px 20px 5px 20px">{{ data1.amounts }}</td>
              <td :key="row2+'g'" style="padding:5px 20px 5px 20px">{{ data1.account_sum }}</td>
            </template>
          </template>
          <td style="padding:5px 20px 5px 20px">{{ data1.sum }}</td>
          <td style="padding:5px 20px 5px 20px">{{ data1.describe }}</td>
        </tr>
      </template>
    </table>
    <div :v-if="list.data">
      <el-pagination
        hide-on-single-page
        :page-size="list.per_page"
        :page-count="list.last_page"
        :current-page="list.current_page"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <br><br><br>

    <el-dialog v-if="dialog_add"
        title="添加新记录"
        :visible.sync="dialog_add"
        width="30%">
        <el-input placeholder="请输入帐户名" v-model="new_account">
            <template slot="prepend">帐户名</template>
        </el-input>
        <br>
        <button style="width:100%" @click="fun_add_account(new_account)">添加</button>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data () {
    return {
      list: {
        data: [],
        total: 1, // 总计
        per_page: 10, // 每页
        last_page: 1 // 最后一页
      },
      dialog_add: false,
      account: { number: '', list: [] },
      bills: { trading_time: '', describe: '', amounts: '' }
    }
  },
  created: function () {
    this.bills_get()
    this.account_get()
    this.bills.trading_time = this.getTime()
  },
  methods: {
    bills_get (current_page = null) {
      this.$axios({
        method: 'post',
        url: '/finance/index/bills_get',
        data: { per_page: this.list.per_page, current_page: current_page }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.list = res.data.data
        }
      })
    },
    account_get () {
      this.$axios({
        method: 'post',
        url: '/finance/index/account_get'
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.account.list = res.data.data
        }
      })
    },
    bills_add () {
      if (isNaN(this.bills.amounts)) { this.$message.error('金额填写异常'); return }
      if (this.bills.amounts === '') { this.$message.error('金额填写异常'); return }
      if (this.bills.describe === '') { this.$message.error('描述填写异常'); return }
      if (this.account.number === '') { this.$message.error('帐户选择异常'); return }
      if (this.bills.trading_time === null) { this.$message.error('时间填写异常'); return }
      this.$axios({
        method: 'post',
        url: '/finance/index/bills_add',
        data: { trading_time: this.bills.trading_time, amounts: this.bills.amounts, describe: this.bills.describe, number: this.account.number }
      }).then(res => {
        if (parseInt(res.data.status) !== 0) {
          this.$message.error(res.data.msg)
        } else {
          this.bills_get()
        }
      })
      this.bills.amounts = ''
      this.bills.describe = ''
    },
    click_fun_edit (number) {
      this.$router.push({ path: '/finance/edit', query: { number: number } })
    },
    handleSizeChange (val) {
      this.bills_get(val)
    },
    handleCurrentChange (val) {
      this.bills_get(val)
    },
    getTime () {
      const yy = new Date().getFullYear()
      var mm =
        new Date().getMonth() < 10
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1
      var dd =
        new Date().getDate() < 10
          ? '0' + new Date().getDate()
          : new Date().getDate()
      const hh = new Date().getHours()
      const mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    }
  }
}
</script>

<style lang="less" scoped>
</style>
